import clsx from 'clsx';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorBox from './ErrorBox';

interface Props {
  prompting: boolean;
  hasVideoPermissions: boolean;
}

const VideoPreview = forwardRef<HTMLVideoElement, Props>(
  ({ prompting, hasVideoPermissions }, ref) => {
    const { t } = useTranslation();

    return (
      <div className={clsx('relative flex flex-1')}>
        <video
          ref={ref}
          className={clsx('aspect-w-16 aspect-h-9 mb-4 w-full rounded-2xl bg-black transition-all')}
          playsInline
          controls={false}
          disablePictureInPicture
        />
        {prompting && !hasVideoPermissions && (
          <div
            className={clsx(
              'absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center p-10 text-center text-base font-semibold leading-6 text-white'
            )}
          >
            {t('askPermissions.tracks.video.empty')}
          </div>
        )}
        <ErrorBox
          className={clsx(
            'absolute left-[50%] top-[50%] z-10 w-[80%] max-w-[400px] flex-1 -translate-x-1/2 -translate-y-1/2 transform'
          )}
          visible={!prompting && !hasVideoPermissions}
          message={t('askPermissions.errorBoxes.camera')}
        />
      </div>
    );
  }
);

VideoPreview.displayName = 'VideoPreview';

export default VideoPreview;
