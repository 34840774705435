import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const VideoIconBordered = ({ color = '#525252', ...props }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.81351 1.08333C1.60981 1.08333 1.44314 1.24583 1.44314 1.44444V7.22222C1.44314 7.42083 1.60981 7.58333 1.81351 7.58333H7.73944C7.94314 7.58333 8.10981 7.42083 8.10981 7.22222V1.44444C8.10981 1.24583 7.94314 1.08333 7.73944 1.08333H1.81351ZM0.332031 1.44444C0.332031 0.647743 0.996383 0 1.81351 0H7.73944C8.55657 0 9.22092 0.647743 9.22092 1.44444V2.18924V6.47743V7.22222C9.22092 8.01892 8.55657 8.66667 7.73944 8.66667H1.81351C0.996383 8.66667 0.332031 8.01892 0.332031 7.22222V1.44444ZM12.5543 6.75729V1.90938L9.96166 3.04688V1.85972L12.376 0.801215C12.4941 0.749306 12.6214 0.722222 12.751 0.722222C13.2556 0.722222 13.6654 1.1217 13.6654 1.61372V7.05295C13.6654 7.54497 13.2556 7.94445 12.751 7.94445C12.6214 7.94445 12.4941 7.91736 12.376 7.86545L9.96166 6.80694V5.61979L12.5543 6.75729Z"
        fill={color}
      />
    </svg>
  );
};

export default VideoIconBordered;
