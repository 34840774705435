import { useEffect, useRef } from 'react';

interface AudioWaveProps {
  audioStream: MediaStream | undefined;
  color: string;
}

const AudioWave = ({ audioStream, color }: AudioWaveProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const audioCtx = new AudioContext();
    const analyser = audioCtx.createAnalyser();
    analyser.fftSize = 256;

    try {
      if (audioStream) {
        const source = audioCtx.createMediaStreamSource(audioStream);
        source.connect(analyser);
      }
    } catch (error) {
      console.error('Error creating MediaStreamSource:', error);
      return;
    }

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    if (audioStream) {
      const draw = () => {
        requestAnimationFrame(draw);
        analyser.getByteFrequencyData(dataArray);

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const barWidth = 8;
        const barSpacing = 16;
        const centerX = canvas.width / 2;
        const maxBarHeight = canvas.height / 2;

        let xLeft = centerX - bufferLength / 2;
        let xRight = centerX + barSpacing / 2;

        for (let i = 0; i < bufferLength / 2; i++) {
          const barHeight = (dataArray[i] / 255) * maxBarHeight;

          ctx.fillStyle = color;
          ctx.beginPath();
          ctx.roundRect(xLeft, canvas.height / 2 - barHeight, barWidth, barHeight * 2, 5);
          ctx.fill();

          ctx.beginPath();
          ctx.roundRect(xRight, canvas.height / 2 - barHeight, barWidth, barHeight * 2, 5);
          ctx.fill();

          xLeft += barWidth + barSpacing;
          xRight += barWidth + barSpacing;
        }
      };

      draw();
    } else {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const dotRadius = 4; // Raggio dei puntini
      const spacing = canvas.width / 8; // Spaziatura uniforme tra i puntini
      const centerY = canvas.height / 2; // Posizione verticale centrale

      ctx.fillStyle = '#DDDDDD';

      for (let i = 1; i <= 7; i++) {
        ctx.beginPath();
        ctx.arc(i * spacing, centerY, dotRadius, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    return () => {
      audioCtx.close();
    };
  }, [audioStream, color]);

  return (
    <canvas
      ref={canvasRef}
      width={146}
      height={104}
    />
  );
};

export default AudioWave;
