import { useRef, useState } from 'react';

const useAudioAnalyser = (
  timingMs: number,
  limitMs: number,
  volumeThreshold: number,
  cb: () => void
) => {
  const [avg, setAvg] = useState<number>(0);

  // Refs
  const interval = useRef<ReturnType<typeof setInterval>>();
  const levels = useRef<number[]>([]); // Buffer degli ultimi N valori

  // Computed
  const sampleCount = Math.floor(limitMs / 1000);
  let intervalRef: ReturnType<typeof setInterval>;

  // Handlers
  const startAnalyser = async () => {
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        sampleSize: 16,
        channelCount: 1,
        noiseSuppression: true,
        echoCancellation: true,
      },
    });

    const ctx = new AudioContext();
    const source = ctx.createMediaStreamSource(audioStream);
    const analyser = ctx.createAnalyser();
    source.connect(analyser);
    analyser.fftSize = 256;

    intervalRef = setInterval(() => {
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(dataArray);
      const max = Math.max(...dataArray);
      const min = Math.min(...dataArray);
      const diff = max - min;

      // Keep a buffer of the last sampleCount values
      if (levels.current.length >= sampleCount) {
        levels.current.shift();
      }
      levels.current.push(diff);

      const diffAvg = levels.current.reduce((sum, val) => sum + val, 0) / levels.current.length;
      setAvg(diffAvg);

      // Verify that all the last sampleCount values are below the threshold
      if (
        levels.current.length === sampleCount &&
        levels.current.every((v) => v < volumeThreshold)
      ) {
        stopAnalyser();
        cb();
      }
    }, timingMs);

    interval.current = intervalRef;
  };

  const stopAnalyser = () => {
    clearInterval(intervalRef);
    interval.current = undefined;
    levels.current = [];
    setAvg(0);
  };

  return {
    avg,
    startAnalyser,
    stopAnalyser,
  };
};

export default useAudioAnalyser;
