import ConnectionIcon from '../../../shared/components/Icons/ConnectionIcon';
import MicrophoneIcon from '../../../shared/components/Icons/MicrophoneIcon';
import VideoIcon from '../../../shared/components/Icons/VideoIcon';

interface Props {
  isAudioOnly?: boolean;
  onlyWifi?: boolean;
}

const ConnectionIcons = ({ isAudioOnly = false, onlyWifi = false }: Props) => {
  return (
    <div className="flex items-center gap-2">
      {/* Audio, Video, Connection icons */}
      {onlyWifi ? (
        <ConnectionIcon color="#1F883D" />
      ) : (
        <>
          {!isAudioOnly && <VideoIcon color="#1F883D" />}
          <MicrophoneIcon color="#1F883D" />
          <ConnectionIcon color="#1F883D" />
        </>
      )}
    </div>
  );
};

export default ConnectionIcons;
