import clsx from 'clsx';
import { MutableRefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SoftSkillQuestionStep, VideoStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import ConnectionStatus from '../../../shared/components/ConnectionStatus/ConnectionStatus';
import EyeCloseIcon from '../../../shared/components/Icons/EyeCloseIcon';
import EyeOpenIcon from '../../../shared/components/Icons/EyeOpenIcon';
import RecLittleIcon from '../../../shared/components/Icons/RecLittleIcon';
import ConnectionIcons from './ConnectionIcons';

interface Props {
  accentColor?: string;
  backgroundColor: string;
  borderColor: string;
  completed: boolean;
  completionPercentage: number;
  connected: boolean;
  countdown: number;
  counters?: { current: number; total: number };
  currentStep: SoftSkillQuestionStep | VideoStep;
  enableStop: boolean;
  player: MutableRefObject<HTMLVideoElement | null>;
  recording: boolean;
  showCountdown: boolean;
  submitted: boolean;
  timer: string;
  onRecording: () => void;
}

const VideoInterview = ({
  connected,
  counters,
  backgroundColor,
  borderColor,
  accentColor,
  currentStep,
  player,
  recording,
  enableStop,
  completed,
  submitted,
  showCountdown,
  timer,
  completionPercentage,
  countdown,
  onRecording,
}: Props) => {
  // Hooks
  const { t } = useTranslation();
  // State
  const [overlay, setOverlay] = useState<boolean>(false);

  return (
    <div className="m-[auto] flex h-full w-full max-w-5xl flex-col items-center justify-center">
      {/* Connection status Banner */}
      <ConnectionStatus
        type="banner"
        connected={connected}
      />
      <div
        className={clsx(
          'relative mx-auto flex h-full w-full flex-col items-center gap-6 overflow-hidden bg-white p-6',
          'lg:max-h-[576px] lg:max-w-[1024px] lg:rounded-lg lg:shadow-cust-1'
        )}
      >
        <div className="flex w-full items-center justify-between">
          {/* Question counter */}
          <div className="text-lg font-bold">
            {t('videoInterview.counter.question')} {counters && counters.current + 1}{' '}
            {t('videoInterview.counter.of')} {counters && counters.total}
          </div>
          <ConnectionIcons />
        </div>
        <div
          className={clsx('relative mb-12 flex w-full rounded-lg border bg-[#FFF0E6] p-6 pb-24')}
          style={{ backgroundColor, borderColor }}
        >
          {/* Question text */}
          <div
            className={clsx(
              'max-h-52 overflow-y-auto text-base font-semibold text-[#111]',
              'lg:max-h-36 lg:text-2xl'
            )}
          >
            {currentStep.data.question.text}
          </div>
          {/* Video Mirror */}
          {/* Video overlay */}
          {overlay && (
            <div
              className={clsx(
                'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
                'h-32 w-32 rounded-full border-8 border-white bg-black bg-opacity-60 backdrop-blur-md',
                { 'z-10': overlay },
                { '-z-10': !overlay }
              )}
            ></div>
          )}
          <video
            ref={player}
            className={clsx(
              'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
              'h-32 w-32 rounded-full border-8 border-white object-cover'
            )}
            playsInline
            muted={true}
            controls={false}
          />

          {/* Overlay Button*/}
          <div
            className={clsx(
              'absolute -bottom-14 left-1/2 z-20 translate-x-8',
              'flex h-10 w-10 cursor-pointer items-center justify-center',
              'rounded-full border-2 border-white bg-[#f0f0f0] text-white backdrop-blur-lg'
            )}
            onClick={() => setOverlay((state: boolean) => !state)}
          >
            {overlay ? <EyeOpenIcon color="#000" /> : <EyeCloseIcon color="#000" />}
          </div>
        </div>
        {/* Footer */}
        <div className="absolute bottom-0 flex w-full flex-col items-center justify-center">
          {/* Retake counter with recording messages */}
          {'retake' in currentStep.data &&
            currentStep.data.retake &&
            currentStep.data.retake.limit > 1 &&
            (recording ? (
              <span
                className={clsx(
                  'flex w-full items-center justify-center gap-2 border-b border-[#DDDDDD] pb-6 font-bold text-[#CF222E]',
                  'lg:border-none'
                )}
              >
                <RecLittleIcon color="#CF222E" />
                {/* (Minimum) Recording messages */}
                {enableStop
                  ? t('videoInterview.retake.counter.recording', {
                      attempt: currentStep.data.retake.current + 1,
                      retake: currentStep.data.retake.limit,
                    })
                  : t('videoInterview.retake.counter.min.recording', {
                      attempt: currentStep.data.retake.current + 1,
                      retake: currentStep.data.retake.limit,
                    })}
              </span>
            ) : (
              <span
                className={clsx(
                  'w-full border-b border-[#DDDDDD] pb-6 text-center font-bold text-[#666666]',
                  'lg:border-none'
                )}
              >
                {t('interviewSimulation.maxTime.message')}{' '}
                {t('videoInterview.retake.counter', {
                  attempt: currentStep.data.retake.current + 1,
                  retake: currentStep.data.retake.limit,
                })}
              </span>
            ))}
          {/* Recording messages */}
          {'retake' in currentStep.data &&
            currentStep.data.retake &&
            currentStep.data.retake.limit <= 1 &&
            recording && (
              <span className="flex items-center gap-2 font-bold text-[#CF222E]">
                <RecLittleIcon color="#CF222E" />
                {enableStop ? t('videoInterview.recording') : t('videoInterview.min.recording')}
              </span>
            )}

          {/* Rec Button */}
          <Button
            className="default my-6 flex gap-2"
            disabled={completed || (!completed && recording && !enableStop)}
            onClick={
              (!completed && !recording) || (!completed && recording && enableStop)
                ? onRecording
                : () => null
            }
          >
            {/* Rec Button text */}
            {!completed && !recording && t('videoInterview.button.record')}
            {!completed && recording && !enableStop && t('videoInterview.button.min.record')}
            {!completed && recording && enableStop && t('videoInterview.button.recording')}
            {/* Rec timer */}
            {recording && <div className="translate-y-[1px] font-mono">{timer}</div>}
          </Button>
        </div>

        <div className="flex items-center justify-center">
          {/* Upload Overlay */}
          <div
            className={clsx(
              'pointer-events-none absolute left-0 top-0 z-[100] flex h-full w-full flex-col items-center justify-center gap-5 bg-white',
              { hidden: !submitted }
            )}
          >
            {/* Upload disclaimer text */}
            <div className="flex flex-col items-center gap-2">
              <div className="text-lg font-semibold text-[#111]">
                {t('videoInterview.upload.disclaimer.title')}
              </div>
              <div className="text-[#626262]">{t('videoInterview.upload.disclaimer.subtitle')}</div>
            </div>
            {/* Upload Bar */}
            <div className="flex w-full max-w-[256px] items-center gap-2">
              <div className="h-2 w-full overflow-hidden rounded-full bg-[#e2e2e2]">
                <div
                  style={{ width: `${completionPercentage}%` }}
                  className={clsx('h-full max-w-full rounded-full transition-all ease-linear', {
                    'bg-gradient-to-r from-[#896efd] to-[#4f28f8]': !accentColor,
                    'bg-[var(--accent-color)]': accentColor,
                  })}
                />
              </div>
              {/* Upload percentage text */}
              <span className="text-xs text-[#666666]">{`${completionPercentage}%`}</span>
            </div>
            <div className="text-[#626262]">{t('videoInterview.upload.loader')}</div>
          </div>
        </div>
        {/* Countdown overlay */}
        <div
          className={clsx(
            'absolute left-0 top-0 z-30 flex h-full w-full flex-col items-center justify-center bg-white bg-opacity-80 p-4 backdrop-blur-md',
            'lg:justify-center',
            { hidden: !showCountdown }
          )}
        >
          {/* Countdown */}
          <div
            className={clsx(
              'flex h-12 w-12 items-center justify-center rounded-full text-3xl font-extrabold backdrop-blur-lg',
              {
                'bg-[#ECECFE] text-[#111]': !accentColor,
                'bg-[var(--accent-color)] text-[var(--text-color)]': accentColor,
              }
            )}
          >
            {countdown}
          </div>
          {/* Countdown Title */}
          <div className="my-2 text-center text-2xl font-bold text-[#111]">
            {t('videoInterview.title')}
          </div>

          {/* Countdown Subtitle */}
          <div className="text-center font-semibold text-[#626262]">
            {t('videoInterview.subtitle')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoInterview;
