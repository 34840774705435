import { SVGAttributes } from 'react';

export default function AudioInterviewIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="48"
        height="48"
        rx="24"
        fill="#6B52FF"
        fillOpacity="0.2"
      />
      <path
        d="M33.6 14H14.4C13.08 14 12.012 15.0654 12.012 16.3676L12 34.8114C12 35.865 13.296 36.3977 14.052 35.6519L16.8 32.941H33.6C34.92 32.941 36 31.8756 36 30.5734V16.3676C36 15.0654 34.92 14 33.6 14Z"
        fill="#6B52FF"
      />
      <g filter="url(#filter0_d_1137_14836)">
        <path
          d="M23.2 27.8V27.164C22.0398 27.0178 20.9728 26.4531 20.1995 25.5759C19.4261 24.6987 18.9996 23.5694 19 22.4H20.2C20.2 23.3548 20.5793 24.2705 21.2544 24.9456C21.9295 25.6207 22.8452 26 23.8 26C24.7548 26 25.6705 25.6207 26.3456 24.9456C27.0207 24.2705 27.4 23.3548 27.4 22.4H28.6C28.6004 23.5694 28.1739 24.6987 27.4005 25.5759C26.6272 26.4531 25.5602 27.0178 24.4 27.164V27.8H26.2V29H21.4V27.8H23.2ZM21.4 19.4C21.4 18.7635 21.6529 18.153 22.1029 17.7029C22.553 17.2529 23.1635 17 23.8 17C24.4365 17 25.047 17.2529 25.4971 17.7029C25.9471 18.153 26.2 18.7635 26.2 19.4V22.4C26.2 23.0365 25.9471 23.647 25.4971 24.0971C25.047 24.5471 24.4365 24.8 23.8 24.8C23.1635 24.8 22.553 24.5471 22.1029 24.0971C21.6529 23.647 21.4 23.0365 21.4 22.4V19.4Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1137_14836"
          x="19"
          y="17"
          width="10.4905"
          height="12.8889"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="0.444444"
            dy="0.444444"
          />
          <feGaussianBlur stdDeviation="0.222222" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1137_14836"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1137_14836"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
