import clsx from 'clsx';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Checkbox from '../../shared/components/Checkbox/Checkbox';
import CompanyDetailsHeader from '../../shared/components/CompanyDetailsHeader/CompanyDetailsHeader';
import { actions as examActions } from '../../store/exam/reducer';
import {
  getCurrentStep,
  getInterviewSimulationDone,
  getJobPostData,
} from '../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import AudioPreview from './AudioPreview';
import Footer from './Footer';
import PermissionsDisclaimer from './PermissionsDisclaimer';
import SupportCTA from './SupportCTA';
import Title from './Title';
import VideoPreview from './VideoPreview';

const { VITE_VOLUME_MULTIPLIER } = import.meta.env;

const AskPermissions = () => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  // Refs
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLDivElement>(null);

  // Selectors
  const interviewSimulationDone = useAppSelector(getInterviewSimulationDone);
  const jobPostData = useAppSelector(getJobPostData);
  const currentStep = useAppSelector(getCurrentStep);

  // States
  const [videoPermission, setVideoPermission] = useState<boolean>(false);
  const [audioPermission, setAudioPermission] = useState<boolean>(false);
  const [userAcceptance, setUserAcceptance] = useState<boolean>(false);
  const [videoPrompting, setVideoPrompting] = useState<boolean>(true);
  const [audioPrompting, setAudioPrompting] = useState<boolean>(true);

  // Computed
  const isAudioOnly =
    jobPostData?.soft_skill_type === 'audio' && currentStep?.group !== 'custom-questions';
  const hasBothPermissions = videoPermission && audioPermission;
  const hasNeededPermissions = isAudioOnly ? audioPermission : hasBothPermissions;
  const allowed = hasNeededPermissions && userAcceptance;

  // Handlers
  const updateVolume = useCallback((analyser: AnalyserNode): void => {
    const update = (): void => {
      const volume = getVolume(analyser);
      if (audioRef.current) {
        audioRef.current.style.width = `${volume * Number(VITE_VOLUME_MULTIPLIER)}%`;
      }
      requestAnimationFrame(update);
    };
    update();
  }, []);

  const requestPermissions = useCallback(async (): Promise<void> => {
    let videoMediaStream: MediaStream | null = null;
    if (!isAudioOnly) {
      try {
        videoMediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
        setVideoPrompting(false);
        const videoTrack = videoMediaStream.getVideoTracks()[0];
        if (videoTrack) {
          await startVideoStream(new MediaStream([videoTrack]));
          setVideoPermission(true);
        } else {
          setVideoPermission(false);
          console.log('Video track not found');
        }
      } catch (e) {
        setVideoPrompting(false);
        setVideoPermission(false);
        console.error('Video permissions not granted:', e);
      }
    }

    let audioMediaStream: MediaStream | null = null;
    try {
      audioMediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioPrompting(false);
      const audioTrack = audioMediaStream.getAudioTracks()[0];
      if (audioTrack) {
        startAudioStream(new MediaStream([audioTrack]), updateVolume);
        setAudioPermission(true);
      } else {
        setAudioPermission(false);
        console.log('Audio track not found');
      }
    } catch (e) {
      setAudioPrompting(false);
      setAudioPermission(false);
      console.error('Audio permissions not granted:', e);
    }
  }, [isAudioOnly, updateVolume]);

  const startVideoStream = async (stream: MediaStream): Promise<void> => {
    if (!videoRef.current || !stream) return;
    if (videoRef.current.srcObject !== null) {
      videoRef.current.pause();
      videoRef.current.srcObject = null;
    }
    videoRef.current.srcObject = stream;
    await videoRef.current.play();
  };

  const startAudioStream = (
    stream: MediaStream,
    updateVolumeCb: (analyser: AnalyserNode) => void
  ): void => {
    if (!audioRef.current || !stream) return;
    const ctx = new AudioContext();
    const source = ctx.createMediaStreamSource(stream);
    const analyser = ctx.createAnalyser();
    source.connect(analyser);
    analyser.fftSize = 256;
    updateVolumeCb(analyser);
  };

  const getVolume = (analyser: AnalyserNode): number => {
    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(dataArray);
    const sum = dataArray.reduce((acc, curr) => acc + curr, 0);
    const average = sum / dataArray.length;
    const normalizedVolume = (average / 255) * 100;
    return normalizedVolume;
  };

  const submitHandler = () => {
    // Set test device done
    dispatch(examActions.TEST_DEVICE_DONE(true));
    // Check if this page has been built from another one. Use this parameter to redirect it back once finished.
    if (state && state.from) {
      navigate(`/${job_post_alias}/${application_alias}/${state.from}`);
      return;
    }

    // Otherwise, we are currently cycling the normal flow
    if (!interviewSimulationDone) {
      navigate(`/${job_post_alias}/${application_alias}/simulate-interview-prompt`);
    } else {
      dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
    }
  };

  return (
    <div className="flex h-full flex-col">
      <div className={clsx('mx-auto flex w-full flex-1 flex-col', 'md:max-w-[840px]')}>
        {/* Company details row */}
        <CompanyDetailsHeader />

        {/* Page content row */}
        <div
          className={clsx('flex flex-1 flex-col p-4 pb-20 pt-0', 'sm:mt-12 sm:flex-none sm:pb-6')}
        >
          {/* Title row */}
          <Title />

          {/* Permissions row */}
          <div className={clsx('mb-4 flex flex-col', 'md:flex-row md:justify-between')}>
            {/* Cam & Mic previews */}
            <div
              className={clsx(
                'flex w-full flex-col rounded-2xl border border-[#D4D4D8] bg-white p-4 md:mr-4 md:max-w-[400px]',
                { 'justify-between': isAudioOnly },
                { 'justify-stretch': !isAudioOnly }
              )}
            >
              {/* Video preview */}
              {!isAudioOnly && (
                <VideoPreview
                  ref={videoRef}
                  prompting={videoPrompting}
                  hasVideoPermissions={videoPermission}
                />
              )}

              {/* Audio preview */}
              <AudioPreview
                ref={audioRef}
                prompting={audioPrompting}
                hasAudioPermissions={audioPermission}
              />
            </div>

            {/* Permission disclaimer & Support CTA */}
            <div
              className={clsx(
                'flex flex-col',
                'sm:flex-row sm:items-stretch sm:justify-between',
                'md:flex-col md:items-start md:justify-start'
              )}
            >
              {/* Permission disclaimer */}
              <PermissionsDisclaimer onClick={requestPermissions} />

              {/* Support CTA */}
              <SupportCTA />
            </div>
          </div>

          {/* Checkbox row */}
          <div className={clsx('checkbox-alt py-2')}>
            <Checkbox
              name="confirmation"
              label={
                isAudioOnly
                  ? t('askPermissions.audioOnly.acceptance.label')
                  : t('askPermissions.acceptance.label')
              }
              disabled={!hasNeededPermissions}
              onChange={(checked: boolean) => setUserAcceptance(checked)}
            />
          </div>

          {/* Disclaimer row */}
          {!isAudioOnly && (
            <div>
              <p className="text-base font-normal leading-6 text-[#71717A]">
                {t('askPermissions.disclaimers.video')}
              </p>
            </div>
          )}
        </div>

        {/* Footer row (bottom-sticked on mobile) */}
        <Footer
          disabled={!allowed}
          onSubmit={submitHandler}
        />
      </div>
      <span
        className={clsx(
          'mb-8 mt-1 hidden items-center justify-center p-2 text-xs font-semibold text-[#525252]',
          'lg:flex'
        )}
      >
        {t('poweredBy')}
      </span>
    </div>
  );
};

export default AskPermissions;
