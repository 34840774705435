import { useCallback, useRef } from 'react';

interface HookReturnProps {
  mediaStream: MediaStream | undefined;
  getStream: ({ video, audio }: { video: boolean; audio: boolean }) => Promise<MediaStream>;
  stopStream: () => void;
}

const MEDIASTREAM_DEFAULT_CONFIGURATIONS: MediaStreamConstraints = {
  video: {
    aspectRatio: 16 / 9,
    frameRate: {
      ideal: 30,
    },
  },
  audio: {
    sampleSize: 16,
    channelCount: 1,
  },
};

const useMediaStream = (): HookReturnProps => {
  // Refs
  const mediaStream = useRef<MediaStream | undefined>();

  /**
   * Handlers
   */
  // Returns a new MediaStream with the given configurations
  const getStream = useCallback(async ({ video = true, audio = true }): Promise<MediaStream> => {
    const constraints: MediaStreamConstraints = {
      video: !video ? false : MEDIASTREAM_DEFAULT_CONFIGURATIONS.video,
      audio: !audio ? false : MEDIASTREAM_DEFAULT_CONFIGURATIONS.audio,
    };
    const stream: MediaStream = await navigator.mediaDevices.getUserMedia(constraints);
    mediaStream.current = stream;
    return stream;
  }, []);

  // Stops the current MediaStream
  const stopStream = (): void => {
    if (mediaStream && mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
      mediaStream.current = undefined;
    }
  };

  // Exposed props
  return {
    mediaStream: mediaStream.current,
    getStream,
    stopStream,
  };
};

export default useMediaStream;
