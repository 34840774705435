/* eslint-disable no-prototype-builtins */
import { createSelector } from '@reduxjs/toolkit';
import { ExamSummaryStep } from '../../interfaces';
import {
  Assessment,
  CustomQuestionnaireQuestionStep,
  FilterQuestionStep,
  HardSkillQuestionStep,
  SoftSkillQuestionStep,
  VideoStep,
} from '../../interfaces/assessment';
import { CompanyData } from '../../interfaces/companyData';
import i18n from '../../shared/utils/i18n';
import { RootState } from '../store';
import { ExamState } from './reducer';

const ExamSlice = (state: RootState) => state.exam;

export const getSid = createSelector(ExamSlice, (state: ExamState): string | null => state.sid);
export const getAuthenticated = createSelector(
  ExamSlice,
  (state: ExamState): boolean => state.authenticated
);
export const getAssessment = createSelector(
  ExamSlice,
  (state: ExamState): Assessment | null => state.assessment
);
export const getCurrentStep = createSelector(ExamSlice, (state: ExamState) => state.currentStep);
export const getCurrentStepIndex = createSelector(
  ExamSlice,
  (state: ExamState) => state.currentStepIndex
);
export const getCurrentQuestionId = createSelector(
  ExamSlice,
  (state: ExamState) =>
    (state.currentStep as SoftSkillQuestionStep | HardSkillQuestionStep)?.data?.question.id
);
export const getCompanyData = createSelector(ExamSlice, (state: ExamState) => state.companyData);

export const getCompanyBrandCustomizations = createSelector(
  getCompanyData,
  (state: CompanyData | null) => state?.customizations?.brand
);

export const getCompanyPagesCustomizations = createSelector(
  getCompanyData,
  (state: CompanyData | null) => state?.customizations?.pages
);

export const getJobPostData = createSelector(ExamSlice, (state: ExamState) => state.jobPostData);

export const getLoading = createSelector(ExamSlice, (state: ExamState): boolean => state.loading);
export const getCompletedChunks = createSelector(
  ExamSlice,
  (state: ExamState): number => state.completed_chunks
);

export const getInterviewSimulationDone = createSelector(
  ExamSlice,
  (state: ExamState): boolean => state.interview_simulation_done
);

export const getTestDeviceDone = createSelector(
  ExamSlice,
  (state: ExamState): boolean => state.test_device_done
);

export const getPresentationInterviewCompletedChunks = createSelector(
  ExamSlice,
  (state: ExamState): number => state.presentation_interview_completed_chunks
);

/** Returns the current filter questions counters (e.g 2/5) */
export const getCurrentFilterQuestionCounters = createSelector(
  [getAssessment, getCurrentStep],
  (assessment, currentStep) => {
    if (assessment && currentStep && currentStep.type === 'filter-question') {
      const filterQuestionStep = currentStep as FilterQuestionStep;
      const allFilterQuestions = assessment.steps.filter(
        (step) => step.type === 'filter-question'
      ) as FilterQuestionStep[];
      const questionIndex = allFilterQuestions.findIndex(
        (step) => step.data.question.id === filterQuestionStep.data.question.id
      );
      return {
        current: questionIndex,
        total: allFilterQuestions.length,
      };
    }
  }
);

// Returns the current soft skill questions counters (e.g 2/5)
export const getCurrentSoftSkillQuestionCounters = createSelector(
  [getAssessment, getCurrentStep],
  (assessment, currentStep) => {
    if (assessment && currentStep && currentStep.type === 'soft-skill-question') {
      // Current step skill name and question
      const { soft_skill_name, question } = (currentStep as SoftSkillQuestionStep).data;
      // Group of soft skills to which the current step belongs -> all "Problem solving" questions
      const currentSoftSkill = assessment.steps.filter(
        (step) =>
          step.type === 'soft-skill-question' && step.data.soft_skill_name === soft_skill_name
      ) as SoftSkillQuestionStep[];
      // Index of the current question of the skill group
      const questionIndex = currentSoftSkill.findIndex(
        (step) => step.data.question.id === question.id
      );
      return {
        current: questionIndex,
        total: currentSoftSkill.length,
      };
    }
  }
);

export const getKillerQuestionsFailedDetails = createSelector(
  ExamSlice,
  (state: ExamState) => state.filters_result
);

export const getSoftSkillQuestionRetakeDetails = createSelector(
  ExamSlice,
  (state: ExamState) => state.soft_skill_question_retake_details
);

/** This checks if every step of the assessment has been completed */
export const checkAllStepsCompleted = createSelector(ExamSlice, (state: ExamState) => {
  return state.assessment?.steps.every((step) => step.completed);
});

/** This is used to compose the summary page. It assembles all the steps from the assessment object */
export const getSummarySteps = createSelector([getAssessment], (assessment) => {
  let steps: ExamSummaryStep[] = [];
  if (assessment) {
    // Retrieve all types of custom questions
    const allCustomVideos: VideoStep[] = assessment.steps.filter(
      (step) => step.type === 'video'
    ) as VideoStep[];
    // TODO: The array of custom questions will include all types of custom questions, not just videos
    const allCustomQuestions: VideoStep[] = [...allCustomVideos];
    if (allCustomQuestions.length > 0) {
      steps = [
        ...steps,
        {
          title: i18n.t('summary.customQuestions.title'),
          duration: allCustomQuestions.reduce((acc, step) => acc + step.data.duration, 0),
          questions: allCustomQuestions.length,
          type: 'Custom Questions',
          completed: allCustomQuestions.every((q) => q.completed),
          current: allCustomQuestions.reduce((acc, step) => acc + (step.completed ? 1 : 0), 0),
          total: allCustomQuestions.length,
        },
      ];
    }
    // Retrieve soft skills
    const softSkillQuestions = (assessment.steps.filter(
      (step) => step.type === 'soft-skill-question'
    ) || []) as SoftSkillQuestionStep[];
    const allSoftSkills = new Set(softSkillQuestions.map((step) => step.data.soft_skill_name));
    for (const softSkill of allSoftSkills) {
      const allQuestions = softSkillQuestions.filter(
        (step) => step.data.soft_skill_name === softSkill
      );
      steps = [
        ...steps,
        {
          title: softSkill,
          duration: allQuestions.reduce((acc, step) => acc + step.data.duration, 0),
          questions: allQuestions.length,
          type: 'Soft Skill',
          completed: allQuestions.every((step) => step.completed),
          current: allQuestions.reduce((acc, step) => acc + (step.completed ? 1 : 0), 0),
          total: allQuestions.length,
        },
      ];
    }
    // Retrieve hard skills
    const hardSkillQuestions = (assessment.steps.filter(
      (step) => step.type === 'hard-skill-question'
    ) || []) as HardSkillQuestionStep[];
    const allHardSkills = new Set(hardSkillQuestions.map((step) => step.data.hard_skill_name));
    for (const hardSkill of allHardSkills) {
      const allQuestions = hardSkillQuestions.filter(
        (step) => step.data.hard_skill_name === hardSkill
      );
      steps = [
        ...steps,
        {
          title: hardSkill,
          duration: allQuestions[0].data.duration,
          questions: allQuestions.length,
          type: 'Hard Skill',
          completed: allQuestions.every((step) => step.completed),
          current: allQuestions.reduce((acc, step) => acc + (step.completed ? 1 : 0), 0),
          total: allQuestions.length,
        },
      ];
    }
    // Retrieve custom questionnaires
    const customQuestionnaireQuestions = (assessment.steps.filter(
      (step) => step.type === 'custom-questionnaire-question'
    ) || []) as CustomQuestionnaireQuestionStep[];
    const allCustomQuestionnaires = new Set(
      customQuestionnaireQuestions.map((step) => step.data.questionnaire_name)
    );
    for (const customQuestionnaire of allCustomQuestionnaires) {
      const allQuestions = customQuestionnaireQuestions.filter(
        (step) => step.data.questionnaire_name === customQuestionnaire
      );
      steps = [
        ...steps,
        {
          title: customQuestionnaire,
          duration: allQuestions[0].data.duration,
          questions: allQuestions.length,
          type: 'Custom Questionnaire',
          completed: allQuestions.every((step) => step.completed),
          current: allQuestions.reduce((acc, step) => acc + (step.completed ? 1 : 0), 0),
          total: allQuestions.length,
        },
      ];
    }
  }

  return steps;
});

// Retrieve all the selected answers for a single questionnaire
export const getHardSkillUserAnswers = createSelector(
  [getAssessment, getCurrentStep],
  (assessment, currentStep) => {
    let answers: number[] = [];
    if (assessment && currentStep) {
      const step = currentStep as HardSkillQuestionStep;
      assessment.steps
        .filter(
          (s) =>
            s.type === 'hard-skill-question' && s.data.hard_skill_name === step.data.hard_skill_name
        )
        .forEach((s) => {
          const _s = s as HardSkillQuestionStep;
          const userAnswer = _s.user_data ? _s.user_data.answer_id : null;
          if (userAnswer) {
            answers = [...answers, userAnswer];
          }
        });
    }
    return answers;
  }
);

// Retrieve the global timer for the hard skill questions
export const getHardSkillGlobalTimer = createSelector(
  ExamSlice,
  (state: ExamState) => state.global_hard_skill_timer
);

/** Returns the current hard skill questions counters (e.g 2/5) */
export const getCurrentHardSkillQuestionCounters = createSelector(
  [getAssessment, getCurrentStep],
  (assessment, currentStep) => {
    if (assessment && currentStep && currentStep.type === 'hard-skill-question') {
      const hardSkillQuestionStep = currentStep as HardSkillQuestionStep;
      const allHardSkillQuestions = assessment.steps.filter(
        (step) =>
          step.type === 'hard-skill-question' &&
          step.data.hard_skill_name === hardSkillQuestionStep.data.hard_skill_name
      ) as HardSkillQuestionStep[];
      const questionIndex = allHardSkillQuestions.findIndex(
        (step) => step.data.question.id === hardSkillQuestionStep.data.question.id
      );
      return {
        current: questionIndex,
        total: allHardSkillQuestions.length,
      };
    }
  }
);

// Retrieve all the selected answers for a single custom questionnaire
export const getCustomQuestionnaireUserAnswers = createSelector(
  [getAssessment, getCurrentStep],
  (assessment, currentStep) => {
    let answers: number[] = [];
    if (assessment && currentStep) {
      const step = currentStep as CustomQuestionnaireQuestionStep;
      assessment.steps
        .filter(
          (s) =>
            s.type === 'custom-questionnaire-question' &&
            s.data.questionnaire_name === step.data.questionnaire_name
        )
        .forEach((s) => {
          const _s = s as CustomQuestionnaireQuestionStep;
          const userAnswer = _s.user_data ? _s.user_data.answer_id : null;
          if (userAnswer) {
            answers = [...answers, userAnswer];
          }
        });
    }
    return answers;
  }
);

// Retrieve the global timer for the questionnaire questions
export const getCustomQuestionnaireGlobalTimer = createSelector(
  ExamSlice,
  (state: ExamState) => state.global_custom_questionnaire_timer
);

/** Returns the current custom questionnaire questions counters (e.g 2/5) */
export const getCurrentCustomQuestionnaireQuestionCounters = createSelector(
  [getAssessment, getCurrentStep],
  (assessment, currentStep) => {
    if (assessment && currentStep && currentStep.type === 'custom-questionnaire-question') {
      const customQuestionnaireQuestionStep = currentStep as CustomQuestionnaireQuestionStep;
      const allCustomQuestionnaireQuestions = assessment.steps.filter(
        (step) =>
          step.type === 'custom-questionnaire-question' &&
          step.data.questionnaire_name === customQuestionnaireQuestionStep.data.questionnaire_name
      ) as CustomQuestionnaireQuestionStep[];
      const questionIndex = allCustomQuestionnaireQuestions.findIndex(
        (step) => step.data.question.id === customQuestionnaireQuestionStep.data.question.id
      );
      return {
        current: questionIndex,
        total: allCustomQuestionnaireQuestions.length,
      };
    }
  }
);
