import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import { DisconnectDescription } from 'socket.io-client/build/esm/socket';
import { FilterQuestionsData } from '../../interfaces/filterQuestionsData';
import { actions as examActions } from '../../store/exam/reducer';
import { getAssessment } from '../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { actions } from '../../store/ui/reducer';
import { MD5 } from '../utils/regex';
import {
  ExamFoundPayload,
  ExamUpdatedPayload,
  JobPostFoundPayload,
  RetakePayload,
} from './socket.interfaces';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const socket: Socket = io(`${API_BASE_URL}/exam`, {
  // withCredentials: true // TODO:Manage Auth
  transports: ['websocket'], // ['polling', 'websocket']
  timeout: 30000, // default 20000
});

interface HookProps {
  socket: Socket;
  connected: boolean;
}

const isStringMD5 = (str: string | undefined): string | null => {
  if (!str) {
    return null;
  }
  return MD5.test(str) ? str : null;
};

const useSocket = (): HookProps => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { job_post_alias: jobPostAliasParam, application_alias: applicationAliasParam } =
    useParams<{
      job_post_alias: string;
      application_alias: string;
    }>();
  const navigate = useNavigate();
  // Selectors
  const assessment = useAppSelector(getAssessment);
  // States
  const [disconnected, setDisconnected] = useState<boolean>(false);
  // Computed
  const job_post_alias = isStringMD5(jobPostAliasParam);
  const application_alias = isStringMD5(applicationAliasParam);
  const storedApplicationAlias = assessment?.application_alias;

  useEffect(() => {
    const connectHandler = () => {
      console.log('[SOCKET] Connected:', socket.connected);
      console.log('[SOCKET] ID:', socket.id);
      if (socket.id) {
        dispatch(examActions.STORE_SOCKET_ID(socket.id));
        if (job_post_alias && application_alias) {
          whoamiHandler(disconnected);
        } else if (job_post_alias && !application_alias) {
          // The applicationAliasParam is a random string, not a MD5 hash
          if (applicationAliasParam && !application_alias) {
            navigate(`/${job_post_alias}/${applicationAliasParam}/not-found`, { replace: true });
          } else {
            // There is no application alias in the parameters
            iamguestHandler();
          }
        } else {
          iamguestHandler();
        }
      }
    };

    const disconnectHandler = (
      reason: Socket.DisconnectReason,
      details: DisconnectDescription | undefined
    ) => {
      console.log('Reason:', reason);
      console.log('Details:', details);
      dispatch(examActions.STORE_SOCKET_ID(null));
      dispatch(examActions.SET_AUTHENTICATED(false));
      setDisconnected(true);
    };

    const softSkillQuestionInterviewChunkProcessedHandler = ({
      _part,
    }: {
      _part: number;
    }): void => {
      dispatch(examActions.UPDATE_COMPLETED_CHUNKS());
    };
    const presentationInterviewChunkProcessedHandler = ({ _part }: { _part: number }): void => {
      dispatch(examActions.UPDATE_PRESENTATION_INTERVIEW_COMPLETED_CHUNKS());
    };

    const softSkillQuestionInterviewPreviewHandler = (payload: RetakePayload) => {
      dispatch(examActions.SOFT_SKILL_QUESTION_RETAKE(payload));
      navigate(`/${job_post_alias}/${application_alias}/soft-skill-interview-preview`, {
        replace: true,
      });
    };

    const whoamiHandler = (disconnected: boolean) => {
      socket.emit('whoami', { job_post_alias, application_alias, silent: disconnected });
    };

    const iamguestHandler = (): void => {
      socket.emit('iamguest', { job_post_alias });
    };

    const guestInvitedHandler = (): void => {
      navigate(`/${job_post_alias}/invited`, { replace: true });
    };

    const examFoundHandler = (payload: ExamFoundPayload) => {
      dispatch(examActions.RETRIEVE_EXAM(payload));
      dispatch(examActions.SET_AUTHENTICATED(true));
      setDisconnected(false);
      if (!payload.silent) {
        navigate(`/${job_post_alias}/${storedApplicationAlias}`, { replace: true });
      } else {
        console.debug(
          `[SOCKET] Connection for ${job_post_alias}/${storedApplicationAlias} restored!`
        );
      }
    };

    const assessmentUpdatedHandler = (payload: ExamUpdatedPayload) => {
      dispatch(examActions.UPDATE_ASSESSMENT(payload));
    };

    const jobPostFoundHandler = (payload: JobPostFoundPayload) => {
      dispatch(examActions.RETRIEVE_JOB_POST(payload));
      navigate(`/${job_post_alias}`, { replace: true });
      setDisconnected(false);
    };

    const okHandler = () => {
      dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
    };
    const killerQuestionsSucceeded = () => {
      dispatch(examActions.DISABLE_LOADING());
      navigate(`/${job_post_alias}/${application_alias}/filter-questions-done`, { replace: true });
    };
    const killerQuestionsFailed = (payload: FilterQuestionsData) => {
      dispatch(examActions.KILLER_QUESTIONS_FAILED(payload));
      navigate(`/${job_post_alias}/${application_alias}/filter-questions-done`, { replace: true });
    };

    // Errors
    const companyNotFoundHandler = (): void => {
      navigate(`/${job_post_alias}/${application_alias}/not-found`, { replace: true });
    };
    const assessmentAlreadyDoneHandler = (payload: ExamFoundPayload): void => {
      if (application_alias) {
        dispatch(examActions.RETRIEVE_EXAM(payload));
        navigate(`/${job_post_alias}/${application_alias}/already-done`, { replace: true });
      } else {
        // If in guest mode is used an email flagged 'already done' neither url param nor store can have application_alias
        navigate(`/${job_post_alias}/guest/already-done`, { replace: true });
      }
    };
    const jobPostNotFoundHandler = (): void => {
      navigate(`/${job_post_alias}/not-found`, { replace: true });
    };
    const applicationNotFoundHandler = (): void => {
      navigate(`/${job_post_alias}/${application_alias}/not-found`, { replace: true }); // PROBLEMA QUI
    };
    const jobPostArchivedHandler = (): void => {
      navigate(`/${job_post_alias}/expired`, { replace: true });
    };
    const guestEmailErrorHandler = (): void => {
      dispatch(
        actions.GUEST_ERROR({ name: 'email', error: t('useSocket.guest.error.duplicateEmail') })
      );
    };
    const blockMultipleConnectionsHandler = (): void => {
      navigate(`/${job_post_alias}/${application_alias}/already-connected`, { replace: true });
    };
    const technicalIssueHandler = (): void => {
      navigate('/technical-issue', { replace: true });
    };
    const deploymentInProgressHandler = (): void => {
      navigate('/updating-platform', { replace: true });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventMap: Record<string, (...args: any) => void> = {
      connect: connectHandler,
      disconnect: disconnectHandler,
      'soft-skill-question-interview-chunk-processed':
        softSkillQuestionInterviewChunkProcessedHandler,
      'soft-skill-question-interview-preview': softSkillQuestionInterviewPreviewHandler,
      'presentation-interview-chunk-processed': presentationInterviewChunkProcessedHandler,
      'exam-found': examFoundHandler,
      'job-post-found': jobPostFoundHandler,
      'assessment-updated': assessmentUpdatedHandler,
      'guest-invited': guestInvitedHandler,
      ok: okHandler,
      'killer-questions-failed': killerQuestionsFailed,
      'killer-questions-succeeded': killerQuestionsSucceeded,
      'block-multiple-connections': blockMultipleConnectionsHandler,
      'company-not-found': companyNotFoundHandler,
      'email-already-exists': guestEmailErrorHandler,
      'job-post-not-found': jobPostNotFoundHandler,
      'assessment-already-done': assessmentAlreadyDoneHandler,
      'application-not-found': applicationNotFoundHandler,
      'job-post-archived': jobPostArchivedHandler,
      'technical-issue': technicalIssueHandler,
      'deployment-in-progress': deploymentInProgressHandler,
    };

    // Register all event handlers
    Object.keys(eventMap).forEach((event) => socket.on(event, eventMap[event]));

    return () => {
      // Unregister all event handlers
      Object.keys(eventMap).forEach((event) => socket.off(event, eventMap[event]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application_alias, disconnected, job_post_alias, storedApplicationAlias]);

  return { socket, connected: !disconnected };
};

export default useSocket;
