import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BriefingStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import CompanyDetailsHeader from '../../../shared/components/CompanyDetailsHeader/CompanyDetailsHeader';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import CircleInfoIcon from '../../../shared/components/Icons/CircleInfoIcon';
import ConnectionIcon from '../../../shared/components/Icons/ConnectionIcon';
import MicrophoneIcon from '../../../shared/components/Icons/MicrophoneIcon';
import VideoIcon from '../../../shared/components/Icons/VideoIcon';
import { SupportEmail } from '../../../shared/components/SupportEmail/SupportEmail';
import { convertHexToRGBA } from '../../../shared/utils/colors';
import { actions as examActions } from '../../../store/exam/reducer';
import {
  getCompanyBrandCustomizations,
  getCurrentStep,
  getJobPostData,
  getTestDeviceDone,
} from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';
import { getBriefingLoading } from '../../../store/ui/selectors';
import BriefingBox from './BriefingBox';

const Briefing = () => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Params
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  // Selectors
  const currentStep = useAppSelector(getCurrentStep) as BriefingStep | null;
  const loading = useAppSelector(getBriefingLoading);
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);
  const jobPostData = useAppSelector(getJobPostData);
  const isDeviceTested = useAppSelector(getTestDeviceDone);

  // Computed
  const backgroundColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 5);
  const borderColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 25);

  // Handlers
  const submitHandler = (): void => {
    dispatch(uiActions.BRIEFING_SUBMIT());
    dispatch(examActions.SET_STEP_COMPLETED());
    if (
      currentStep &&
      ['soft-skills', 'custom-questions'].includes(currentStep.group) &&
      !isDeviceTested
    ) {
      navigate(`/${job_post_alias}/${application_alias}/test-devices`, { replace: true });
    } else {
      dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
    }
  };

  // Effects
  useEffect(() => {
    dispatch(uiActions.BRIEFING_START());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentStep || currentStep.type !== 'briefing') {
    return null;
  }

  const isAudio = jobPostData?.soft_skill_type === 'audio';

  return (
    // page container
    <div className={clsx('mx-auto flex h-full w-full flex-col', 'md:max-w-[840px]')}>
      {/* Company header */}
      <CompanyDetailsHeader className="hidden lg:flex" />

      {/* Card container */}
      <div className="flex w-full max-w-5xl flex-1 flex-col items-center justify-start lg:justify-center">
        {/* Card */}
        <div className="flex h-full w-full flex-col items-center justify-start bg-[#FFFFFF] p-4 lg:h-auto lg:rounded-[8px] lg:p-6 lg:shadow-cust-1">
          <div className="my-4 flex w-full flex-row items-center justify-between">
            {/* Skill title and type */}
            <div className="flex flex-col">
              <HeadingText className="font-bold">
                {currentStep.group === 'filter-questions' && t('briefing.filterQuestions.title')}
                {currentStep.group === 'custom-questions' && t('briefing.customQuestions.title')}
                {(currentStep.group === 'soft-skills' || currentStep.group === 'hard-skills') &&
                  currentStep.data.title}
                {currentStep.group === 'custom-questionnaires' && currentStep.data.title}
              </HeadingText>
              <HeadingText variant="secondary">
                {currentStep.group === 'soft-skills' && t('briefing.test.type.softSkill')}
                {currentStep.group === 'hard-skills' && t('briefing.test.type.hardSkill')}
                {currentStep.group === 'custom-questionnaires' &&
                  t('briefing.test.type.customQuestionnaire')}
              </HeadingText>
            </div>
            <div className="flex items-center gap-2">
              {/* Audio, Video, Connection icons */}
              {!isAudio && <VideoIcon color="#1F883D" />}

              <MicrophoneIcon color="#1F883D" />
              <ConnectionIcon color="#1F883D" />
            </div>
          </div>

          {/* Recommendations for use */}
          <div
            className="my-4 flex w-full flex-col justify-start rounded-2xl border p-4"
            style={{ backgroundColor, borderColor }}
          >
            <div className="mb-2 flex flex-row items-center justify-start">
              <CircleInfoIcon
                color="var(--accent-color)"
                className="mr-2"
              />
              <strong>
                <HeadingText variant="primary-small">{t('briefing.infoBox.title')}</HeadingText>
              </strong>
            </div>

            <div className="flex flex-row items-start justify-start px-6">
              <ol className="list-decimal text-[#111111]">
                <li>
                  <span className="ml-1">{t('briefing.infoBox.text2')}</span>
                </li>
                <li>
                  <span className="ml-1">{t('briefing.infoBox.text3')}</span>
                </li>
                <li>
                  <span className="ml-1">{t('briefing.infoBox.text4')}</span>
                </li>
              </ol>
            </div>
          </div>

          {/* Row with info box widgets */}
          <h3 className="mt-4 self-start text-lg font-semibold text-[#09090b]">
            {t('briefingBox.title')}
          </h3>
          <BriefingBox
            isAudio={isAudio}
            type={currentStep.group}
            questions={currentStep.data.questions}
            duration={currentStep.data.duration}
            retakes={currentStep.data.attempts}
          />

          {/* Footer desktop */}
          <div className="hidden w-full flex-col items-center justify-between bg-[#FFFFFF] p-4 lg:flex lg:pb-0">
            <Button
              loading={loading}
              className="default"
              onClick={submitHandler}
            >
              {t('briefing.button.next')}
            </Button>
          </div>
        </div>
      </div>

      {/* Footer mobile */}
      <div className="sticky bottom-0 flex w-full flex-col items-center justify-between border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] p-4 lg:hidden">
        <Button
          loading={loading}
          className="default"
          onClick={submitHandler}
        >
          {t('briefing.button.next')}
        </Button>
        <div className="flex flex-col items-center justify-center pt-4">
          <div className="text-sm">
            {t('needHelp')} {t('contactUs')} <SupportEmail />
          </div>
          <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
        </div>
      </div>

      {/* Support Desktop */}
      <div className="mb-6 hidden flex-col items-center justify-center pt-4 lg:flex">
        <div className="text-sm">
          {t('needHelp')} {t('contactUs')} <SupportEmail />
        </div>
        <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
      </div>
    </div>
  );
};

export default Briefing;
