import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const { VITE_APP_SUPPORT_EMAIL } = import.meta.env;

const SupportCTA = () => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'mt-4 flex flex-col rounded-2xl border-[1px] border-[#D4D4D8] bg-white p-4',
        'sm:w-[48%] sm:justify-between',
        'md:mt-0 md:w-full md:max-w-[400px]'
      )}
    >
      <p className="mb-1 text-base font-semibold text-[#09090B]">
        {t('askPermissions.disclaimers.support.title')}
      </p>
      <p className="mb-4 text-sm font-normal text-[#222222] opacity-65">
        {t('askPermissions.disclaimers.support.subtitle')}
      </p>
      <a
        href={`mailto:${VITE_APP_SUPPORT_EMAIL}`}
        target="_blank"
        rel="noreferrer noopener"
        className="btn secondary alt !max-w-full !rounded-lg"
      >
        {t('askPermissions.disclaimers.support.button')}
      </a>
    </div>
  );
};

export default SupportCTA;
