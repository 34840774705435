import { useNavigate } from 'react-router-dom';
import { Socket } from 'socket.io-client';

export enum ErrorCodes {
  INTERVIEW_START = 'INTERVIEW_ERR_001',
  INTERVIEW_EVENT_ERROR = 'INTERVIEW_ERR_002',
  INTERVIEW_INIT_STREAM = 'INTERVIEW_ERR_003',
  INTERVIEW_SEND_CHUNK_TIMEOUT = 'INTERVIEW_ERR_004',
  INTERVIEW_SEND_CHUNK = 'INTERVIEW_ERR_005',
  INTERVIEW_INIT_RECORDER = 'INTERVIEW_ERR_006',
  INTERVIEW_SEND_CHUNK_BACKEND_ERROR = 'INTERVIEW_ERR_007',
  INTERVIEW_SEND_QUEUE_LENGTH_ERROR = 'INTERVIEW_ERR_008',
}

const useErrorHandler = (socket: Socket) => {
  const navigate = useNavigate();

  /**
   * Handles errors by sending them to the server via socket and redirecting the user to an error page.
   * @param {ErrorCodes} errorCode - A predefined error code that identifies the type of error.
   * @param {Record<string, unknown>} [payload] - (Optional) Additional data to include with the error,
   *        such as a `question_id`, an error message, or other useful debugging details.
   * @param {Error} [error] - (Optional) The original error object. If provided, its stack trace will be included
   *        in the payload. If not provided, a new stack trace will be generated automatically.
   * @example
   * handleError(ErrorCodes.SOCKET_ERROR, { question_id: '123', message: 'Connection lost' });
   */
  const handleError = (
    errorCode: ErrorCodes,
    payload?: Record<string, unknown>,
    error?: Error
  ): void => {
    // Combine the error code with any additional data
    const errorPayload = { code: errorCode, ...payload, stack: error?.stack || new Error().stack };
    socket.emit('camera-error', errorPayload);
    // Redirect
    navigate(`/technical-issue`, { replace: true, state: { code: errorCode } });
  };

  return { handleError };
};

export default useErrorHandler;
