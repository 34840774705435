import clsx from 'clsx';
import { useAppSelector } from '../../../store/store';
import { getCompanyBrandCustomizations } from '../../../store/exam/selectors';
import { useTranslation } from 'react-i18next';
import { SoftSkillQuestionStep } from '../../../interfaces/assessment';

interface CountDownOverlayProps {
  showCountdown: boolean;
  countdown: number;
  isAudio: boolean;
  currentStep?: SoftSkillQuestionStep | null;
}

const CountDownOverlay = ({
  showCountdown,
  countdown,
  isAudio,
  currentStep,
}: CountDownOverlayProps) => {
  const { t } = useTranslation();
  // Selectors
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  return (
    <div
      className={clsx(
        'absolute left-0 top-0 z-30 flex h-full w-full flex-col items-center justify-center rounded-xl bg-white bg-opacity-80 p-4 backdrop-blur-md',
        'lg:justify-center',
        { hidden: !showCountdown }
      )}
    >
      {/* Countdown title when is audio interview*/}
      {isAudio && (
        <div className="text-l my-2 text-center font-[700] text-[#111]">
          {t('audioInterview.title')}
        </div>
      )}
      {/* Countdown */}
      <div
        className={clsx(
          'flex h-12 w-12 items-center justify-center rounded-full text-3xl font-extrabold backdrop-blur-lg',
          {
            'bg-[#ECECFE] text-[#111]': !brandCustomization?.['accent-color'],
            'bg-[var(--accent-color)] text-[var(--text-color)]':
              brandCustomization?.['accent-color'],
          }
        )}
      >
        {countdown}
      </div>
      {isAudio && currentStep?.data.retake && currentStep.data.retake.limit > 1 && (
        <span className="pt-4 text-base font-[600] text-[#525252]">
          {t('audioInterview.answer.retake')}{' '}
          {`${currentStep.data.retake.current + 1}/${currentStep.data.retake.limit}`}
        </span>
      )}
      {!isAudio && (
        <>
          {/* Countdown Title */}
          <div className="my-2 text-center text-2xl font-bold text-[#111]">
            {t('videoInterview.title')}
          </div>

          {/* Countdown Subtitle */}
          <div className="text-center font-semibold text-[#626262]">
            {t('videoInterview.subtitle')}
          </div>
        </>
      )}
    </div>
  );
};
export default CountDownOverlay;
