import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

export default function MicrophoneIconBordered({ color = '#525252', ...props }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.27143 2.83337V7.00004C6.27143 7.69015 5.70135 8.25004 4.9987 8.25004C4.29605 8.25004 3.72597 7.69015 3.72597 7.00004V2.83337C3.72597 2.14327 4.29605 1.58337 4.9987 1.58337C5.70135 1.58337 6.27143 2.14327 6.27143 2.83337ZM2.45324 2.83337V7.00004C2.45324 8.38025 3.5934 9.50004 4.9987 9.50004C6.404 9.50004 7.54415 8.38025 7.54415 7.00004V2.83337C7.54415 1.45317 6.404 0.333374 4.9987 0.333374C3.5934 0.333374 2.45324 1.45317 2.45324 2.83337ZM1.60476 5.95837C1.60476 5.61202 1.32105 5.33337 0.968395 5.33337C0.615743 5.33337 0.332031 5.61202 0.332031 5.95837V7.00004C0.332031 9.32035 2.08733 11.237 4.36233 11.5417V12.4167H3.08961C2.73696 12.4167 2.45324 12.6954 2.45324 13.0417C2.45324 13.3881 2.73696 13.6667 3.08961 13.6667H4.9987H6.90779C7.26044 13.6667 7.54415 13.3881 7.54415 13.0417C7.54415 12.6954 7.26044 12.4167 6.90779 12.4167H5.63506V11.5417C7.91006 11.237 9.66537 9.32035 9.66537 7.00004V5.95837C9.66537 5.61202 9.38165 5.33337 9.029 5.33337C8.67635 5.33337 8.39264 5.61202 8.39264 5.95837V7.00004C8.39264 8.84119 6.87332 10.3334 4.9987 10.3334C3.12408 10.3334 1.60476 8.84119 1.60476 7.00004V5.95837Z"
        fill={color}
      />
    </svg>
  );
}
