import clsx from 'clsx';
import { HTMLAttributes } from 'react';
import TriangleRedExclamationAltIcon from '../../shared/components/Icons/TriangleRedExclamationAltIcon';

interface Props extends HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  message: string;
}

const ErrorBox = ({ visible, message, ...props }: Props) => {
  if (!visible) return null;

  return (
    <div
      className={clsx(
        'flex items-center justify-start rounded-lg bg-[#FFEAEC] p-2',
        props.className
      )}
    >
      <div className="mr-2">
        <TriangleRedExclamationAltIcon
          width={32}
          height={32}
          color="#9F1300"
        />
      </div>
      <p className="text-sm font-bold leading-5 text-[#111111]">{message}</p>
    </div>
  );
};

export default ErrorBox;
