import clsx from 'clsx';
import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { SoftSkillQuestionStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import ConnectionStatus from '../../../shared/components/ConnectionStatus/ConnectionStatus';
import RecLittleIcon from '../../../shared/components/Icons/RecLittleIcon';
import { getCount } from '../../../shared/utils/getCountDown';
import AudioWave from './AudioWave';
import ConnectionIcons from './ConnectionIcons';
import CountDownOverlay from './CountDownOverlay';
import UploadOverlay from './UploadOverlay';

interface Props {
  accentColor?: string;
  backgroundColor: string;
  completed: boolean;
  completionPercentage: number;
  connected: boolean;
  countdown: number;
  counters?: { current: number; total: number };
  currentStep: SoftSkillQuestionStep;
  timer: string;
  enableStop: boolean;
  player: MutableRefObject<HTMLAudioElement | null>;
  recording: boolean;
  showCountdown: boolean;
  submitted: boolean;
  duration: number;
  stream: MediaStream | undefined;
  onRecording: () => void;
}

const AudioInterview = ({
  connected,
  counters,
  backgroundColor,
  accentColor,
  currentStep,
  player,
  recording,
  enableStop,
  completed,
  submitted,
  showCountdown,
  duration,
  completionPercentage,
  countdown,
  stream,
  timer,
  onRecording,
}: Props) => {
  // Hooks
  const { t } = useTranslation();
  const interviewDuration = getCount(duration);

  return (
    <div className="m-[auto] flex h-[100vh] w-full flex-col items-center justify-center">
      {/* Connection status Banner */}
      <ConnectionStatus
        type="banner"
        connected={connected}
      />
      <div
        style={{ backgroundColor }}
        className={clsx(
          'relative mx-auto flex h-full w-full flex-col items-stretch justify-between gap-32 overflow-auto p-2',
          'lg:flex-row lg:p-8 xl:p-16'
        )}
      >
        {/* Right column desktop - question container */}
        <div className="w-full lg:w-[50%]">
          <div className="pb-6 text-base font-[600] text-[#525252] lg:pb-2">
            {t('videoInterview.counter.question')} {counters && counters.current + 1}{' '}
            {t('videoInterview.counter.of')} {counters && counters.total}
          </div>

          {/* Question text */}
          <div className={clsx('text-xl font-medium text-[#111]', 'lg:text-4xl')}>
            {currentStep.data.question.text}
          </div>
        </div>

        {/* Left column desktop - interview container */}
        <div className="relative flex h-auto flex-col items-stretch justify-between rounded-xl bg-white p-4 shadow-cust-1 lg:h-full lg:w-[50%] lg:p-6 xl:p-8">
          {/* Countdown overlay */}
          <CountDownOverlay
            currentStep={currentStep}
            showCountdown={showCountdown}
            countdown={countdown}
            isAudio
          />
          <div className="flex justify-between text-base font-[600] text-[#111]">
            <span> {t('audioInterview.answer.title')}</span>
            <div className="flex">
              {currentStep.data.retake && currentStep.data.retake.limit > 1 && (
                <span className="pr-2">
                  {t('audioInterview.answer.retake')}{' '}
                  {`${currentStep.data.retake.current + 1}/${currentStep.data.retake.limit}`}
                </span>
              )}
              <ConnectionIcons isAudioOnly />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <audio
              ref={player}
              muted={true}
              style={{ display: 'none' }}
            ></audio>
            <AudioWave
              audioStream={stream}
              color={accentColor || '#038939'}
            />
            {/* Rec timer */}

            <span className="mt-4 text-2xl font-bold text-[#111] lg:mt-8 lg:text-5xl">
              {recording ? `${timer}/${interviewDuration}` : `00:00/${interviewDuration}`}
            </span>
            {recording && (
              <span className="mt-4 flex items-center justify-center gap-2 font-bold text-[#CF222E]">
                <RecLittleIcon color="#CF222E" />
                {enableStop ? t('videoInterview.recording') : t('videoInterview.min.recording')}
              </span>
            )}
          </div>

          {/* Rec Button */}
          <div className="flex w-full justify-center">
            <Button
              className={clsx('default lg:w-30 mt-6 flex w-full !max-w-none gap-2')}
              disabled={completed || (!completed && recording && !enableStop)}
              onClick={
                (!completed && !recording) || (!completed && recording && enableStop)
                  ? onRecording
                  : () => null
              }
            >
              {/* Rec Button text */}
              {!completed && !recording && t('videoInterview.button.record')}
              {!completed && recording && t('videoInterview.button.recording')}
            </Button>
          </div>
        </div>
      </div>
      {/* Upload overlay */}
      <UploadOverlay
        completionPercentage={completionPercentage}
        submitted={submitted}
      />
    </div>
  );
};

export default AudioInterview;
