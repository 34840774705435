import clsx from 'clsx';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSocketInstance } from '../../../App';
import Button from '../../../shared/components/Button/Button';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import JobHeader from '../../../shared/components/JobHeader/JobHeader';
import { EMAIL_REGEX } from '../../../shared/utils/regex';
import { getCompanyData, getJobPostData } from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { GuestUiErrors, actions } from '../../../store/ui/reducer';
import { getGuestErrors, getGuestLoading } from '../../../store/ui/selectors';

interface FormState {
  name: { value: string; valid: boolean; touched: boolean };
  surname: { value: string; valid: boolean; touched: boolean };
  email: { value: string; valid: boolean; touched: boolean };
}

interface Policies {
  company_policy: boolean;
  algo_interview_policy: boolean;
}

const ALGO_PRIVACY_POLICY_URL = import.meta.env.VITE_ALGO_PRIVACY_POLICY_URL;

const Guest = () => {
  const { socket } = useSocketInstance();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // Selectors
  const loading = useAppSelector(getGuestLoading);
  const company = useAppSelector(getCompanyData);
  const jobPostData = useAppSelector(getJobPostData);
  const errors = useAppSelector(getGuestErrors);

  // States
  const [state, setState] = useState<FormState>({
    name: { value: '', valid: false, touched: false },
    surname: { value: '', valid: false, touched: false },
    email: { value: '', valid: false, touched: false },
  });

  const [formErrors, setFormErrors] = useState<GuestUiErrors>({});

  const validateInput = (value: string, type: string): boolean => {
    let valid = false;
    let error = '';
    switch (type) {
      case 'name':
      case 'surname': {
        valid = value.trim().length >= 2;
        error = t('guest.input.name.error');
        break;
      }
      case 'email': {
        valid = EMAIL_REGEX.test(value.trim());
        error = t('guest.input.email.error');
        break;
      }
    }
    if (!valid) {
      setFormErrors((state) => ({
        ...state,
        [type as keyof GuestUiErrors]: error,
      }));
    } else {
      const newState = { ...formErrors };
      delete newState[type as keyof GuestUiErrors];
      setFormErrors(newState);
    }
    return valid;
  };

  const changeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    setState((prev: FormState) => ({
      ...prev,
      [e.target.name as keyof FormState]: {
        value: e.target.value,
        valid: validateInput(e.target.value, e.target.name),
        touched: true,
      },
    }));
  };

  const submitHandler = async (): Promise<void> => {
    dispatch(actions.GUEST_SUBMIT());
    const payload = {
      name: state.name.value,
      surname: state.surname.value,
      email: state.email.value.toLowerCase().trim(),
    };
    dispatch(actions.GUEST_INVITED(payload));
    socket.emit('guest-authenticate', payload);
  };

  const [checkboxes, setCheckboxes] = useState<Policies>({
    company_policy: false,
    algo_interview_policy: false,
  });

  const allCheckboxesChecked: boolean = Object.values(checkboxes).every((value: boolean) => value);

  useEffect(() => {
    dispatch(actions.GUEST_ERROR_RESET_ALL());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formErrors.name) {
      dispatch(actions.GUEST_ERROR({ name: 'name', error: formErrors['name'] }));
    } else {
      dispatch(actions.GUEST_ERROR_RESET('name'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors.name]);

  useEffect(() => {
    if (formErrors.surname) {
      dispatch(actions.GUEST_ERROR({ name: 'surname', error: formErrors['surname'] }));
    } else {
      dispatch(actions.GUEST_ERROR_RESET('surname'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors.surname]);

  useEffect(() => {
    if (formErrors.email) {
      dispatch(actions.GUEST_ERROR({ name: 'email', error: formErrors['email'] }));
    } else {
      dispatch(actions.GUEST_ERROR_RESET('email'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors.email]);

  return (
    <div
      className={clsx(
        'mx-auto flex h-full w-full max-w-[1600px] flex-col p-4',
        'lg:max-h-[980px] lg:flex-row-reverse lg:items-stretch lg:gap-32 lg:p-16'
      )}
    >
      {/* Cover section */}
      <div className={clsx('mb-4 flex w-full', 'lg:w-auto lg:flex-1')}>
        {/* Mobile cover */}
        {jobPostData?.['mobile_cover'] && (
          <div className={clsx('flex h-[240px] w-full rounded-2xl', 'lg:hidden')}>
            <img
              src={jobPostData['mobile_cover']}
              alt="Mobile cover"
              className="aspect-[16/9] w-full object-contain object-center"
            />
          </div>
        )}
        {/* Desktop cover */}
        {jobPostData?.['desktop_cover'] && (
          <div className={clsx('hidden h-full w-full rounded-2xl', 'lg:flex')}>
            <img
              src={jobPostData['desktop_cover']}
              alt="Desktop cover"
              className="aspect-[4/5] w-full object-contain object-center"
            />
          </div>
        )}
      </div>

      {/* Form section */}
      <div className={clsx('flex w-full', 'lg:w-auto lg:flex-1 lg:flex-col')}>
        <div className="lg:max-w-[480px]">
          <JobHeader />

          <div
            className={clsx(
              'mt-5 flex w-full flex-col items-start justify-stretch px-4',
              'lg:max-h-[270px] lg:px-0'
            )}
          >
            <div className="mb-4 w-full">
              <label
                htmlFor="name"
                className="mb-2 block text-[15px]"
              >
                {t('guest.form.name.label')}
              </label>
              <input
                required
                id="name"
                name="name"
                className={clsx(
                  'text-md flex h-10 w-full rounded-md border bg-[#ffffff] px-3 py-2 placeholder:text-gray-600 focus:border-[2px] focus:border-[#4344dc] disabled:cursor-not-allowed disabled:opacity-50',
                  { 'border-[2px] border-[#df1b41]': errors['name'] }
                )}
                type="text"
                placeholder={t('guest.form.name.placeholder')}
                value={state.name.value}
                onChange={changeHandler}
              />
              {errors['name'] && <div className="text-xs text-[#df1b41]">{errors['name']}</div>}
            </div>
            <div className="mb-4 w-full">
              <label
                htmlFor="surname"
                className="mb-2 block text-[15px]"
              >
                {t('guest.form.surname.label')}
              </label>
              <input
                required
                id="surname"
                name="surname"
                className={clsx(
                  'text-md flex h-10 w-full rounded-md border bg-[#ffffff] px-3 py-2 placeholder:text-gray-600 focus:border-[2px] focus:border-[#4344dc] disabled:cursor-not-allowed disabled:opacity-50',
                  { 'border-[2px] border-[#df1b41]': errors['surname'] }
                )}
                type="text"
                placeholder={t('guest.form.surname.placeholder')}
                value={state.surname.value}
                onChange={changeHandler}
              />
              {errors['surname'] && (
                <div className="text-xs text-[#df1b41]">{errors['surname']}</div>
              )}
            </div>
            <div className="mb-4 w-full">
              <label
                htmlFor="email"
                className="mb-2 block text-[15px]"
              >
                {t('guest.form.email.label')}
              </label>
              <input
                required
                id="email"
                name="email"
                className={clsx(
                  'text-md flex h-10 w-full rounded-md border bg-[#ffffff] px-3 py-2 placeholder:text-gray-600 focus:border-[2px] focus:border-[#4344dc] disabled:cursor-not-allowed disabled:opacity-50',
                  { 'border-[2px] border-[#df1b41]': errors['email'] }
                )}
                type="email"
                placeholder={t('guest.form.email.placeholder')}
                value={state.email.value}
                onChange={changeHandler}
              />
              {errors['email'] && <div className="text-xs text-[#df1b41]">{errors['email']}</div>}
            </div>
          </div>

          <div className="my-2 flex w-full flex-col justify-start px-4 lg:px-0">
            <div
              className={clsx('mb-2 w-full text-base font-normal text-[#b37800]', {
                hidden: allCheckboxesChecked,
              })}
            >
              {t('guest.privacy.disclaimer')}
            </div>
            <div className="mb-20 flex w-full flex-col lg:mb-0">
              {Object.keys(checkboxes).map((checkbox: string) => (
                <Checkbox
                  key={`introduction-checkbox-${checkbox}`}
                  name={checkbox}
                  label={
                    checkbox === 'company_policy'
                      ? t(`guest.privacy.${checkbox}`, {
                          companyName: company?.name,
                          companyPolicyUrl: company?.privacy_policy_url,
                        })
                      : checkbox === 'algo_interview_policy'
                        ? t(`guest.privacy.${checkbox}`, {
                            algoPolicyUrl: ALGO_PRIVACY_POLICY_URL,
                          })
                        : t(`guest.privacy.${checkbox}`)
                  }
                  onChange={(checked: boolean) =>
                    setCheckboxes((state) => ({
                      ...state,
                      [checkbox]: checked,
                    }))
                  }
                />
              ))}
            </div>
          </div>
          <div
            className={clsx(
              'border-top fixed bottom-0 left-0 right-0 border-t-[1px] border-[#DDDDDD] bg-white p-4',
              'sm:static sm:mt-10 sm:border-0 sm:bg-transparent sm:p-0'
            )}
          >
            <Button
              loading={loading}
              disabled={
                !allCheckboxesChecked ||
                !state.name.valid ||
                !state.surname.valid ||
                !state.email.valid
              }
              className={clsx(
                'default mx-auto !w-full !rounded-lg !border-transparent',
                'sm:mx-0 sm:!w-auto'
              )}
              onClick={submitHandler}
            >
              {t('guest.button.start')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guest;
