import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getCompanyBrandCustomizations } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';

interface Props {
  completionPercentage: number;
  submitted: boolean;
}

const UploadOverlay = ({ completionPercentage, submitted }: Props) => {
  // Hooks
  const { t } = useTranslation();
  // Selectors
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  return (
    <div className="flex items-center justify-center">
      <div
        className={clsx(
          'pointer-events-none absolute left-0 top-0 z-[100] flex h-full w-full flex-col items-center justify-center gap-5 bg-white',
          { hidden: !submitted }
        )}
      >
        <div
          className={clsx(
            'flex flex-col items-center gap-2 rounded-xl',
            'lg:border lg:p-32 lg:shadow-cust-1'
          )}
        >
          {/* Upload disclaimer text */}
          <div className="flex flex-col items-center gap-2">
            <div className="text-lg font-semibold text-[#111]">
              {t('videoInterview.upload.disclaimer.title')}
            </div>
            <div className="text-[#626262]">{t('videoInterview.upload.disclaimer.subtitle')}</div>
          </div>
          {/* Upload Bar */}
          <div className="flex w-full max-w-[256px] items-center gap-2">
            <div className="h-2 w-full overflow-hidden rounded-full bg-[#e2e2e2]">
              <div
                style={{ width: `${completionPercentage}%` }}
                className={clsx('h-full max-w-full rounded-full transition-all ease-linear', {
                  'bg-gradient-to-r from-[#896efd] to-[#4f28f8]':
                    !brandCustomization?.['accent-color'],
                  'bg-[var(--accent-color)]': brandCustomization?.['accent-color'],
                })}
              />
            </div>
            {/* Upload percentage text */}
            <span className="text-xs text-[#666666]">{`${completionPercentage}%`}</span>
          </div>
          <div className="text-[#626262]">{t('videoInterview.upload.loader')}</div>
        </div>
      </div>
    </div>
  );
};

export default UploadOverlay;
