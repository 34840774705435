import { MIMETYPE_LIST, MimeTypes, MimeTypesWithCodec } from '../utils/mimetypes';

export interface IChunkPayload {
  id?: number;
  type: 'start' | 'chunk' | 'end' | 'aborted';
  data?: Blob;
  elapsed_time: number;
  sent_at: number | null;
  part?: number;
}

export enum ChunkTypeEnum {
  START = 'start',
  CHUNK = 'chunk',
  END = 'end',
  ABORTED = 'aborted',
}

export type ChunkType = Lowercase<keyof typeof ChunkTypeEnum>;

export class ChunkPayload implements IChunkPayload {
  // Binary data of the chunk (this exists only in the "chunk" type)
  public data?: Blob;

  // Send time of the chunk (expressed as Unix timestamp)
  public sent_at: number | null = null;

  // Part of the chunk (expressed as integer)
  public part?: number;

  // Public constructor
  constructor(
    public id: number,
    public type: ChunkType,
    public elapsed_time: number,
    data?: Blob
  ) {
    if (data) {
      this.data = data;
    }
  }

  // Set part
  public setPart(part: number): ChunkPayload {
    this.part = part;
    return this;
  }

  // Set sent time
  public setSent(): void {
    this.sent_at = Date.now();
  }
}

export class ChunksQueue {
  // Id of the question
  private question_id: number = 0;

  // Interval to keep track of the elapsed time
  private interval: ReturnType<typeof setInterval> | null = null;

  // Elapsed time
  private elapsed: number = 0;

  // Start chunk of the queue
  private startChunk?: ChunkPayload;

  // End chunk of the queue
  private endChunk?: ChunkPayload;

  // Queue that contains the raw buffers
  public rawQueue: { blob: Blob; elapsed: number }[] = [];

  // Queue that contains the processed and ordered chunks
  private processedQueue: ChunkPayload[] = [];

  // Method to start the interval
  private startInterval(): void {
    this.interval = setInterval(() => ++this.elapsed, 1000);
  }

  // Method to stop the interval
  private stopInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  // Method to get the elapsed time
  public getElapsedTime(): number {
    return this.elapsed;
  }

  // Method to reset the elapsed time
  public resetElapsedTime(): void {
    this.elapsed = 0;
  }

  // Method to initialize the queue
  public start(question_id: number): void {
    this.question_id = question_id;
    this.startInterval();
  }

  // Method to add a chunk to the queue
  public addChunk(blob: Blob): void {
    if (blob && blob.size > 0) {
      this.rawQueue.push({ blob, elapsed: this.getElapsedTime() });
    }
  }

  // Method to get the mime type
  public getMimeType(): { mime: string; ext: string } {
    const possibleTypes: MimeTypesWithCodec[] = MIMETYPE_LIST.filter((mimeType) =>
      MediaRecorder.isTypeSupported(mimeType)
    );

    const availableMimeTypes = possibleTypes.reduce(
      (acc: Record<MimeTypes, string[]>, val) => {
        const type: MimeTypes = val.split(';')[0] as MimeTypes;
        acc[type] = [...acc[type], val];
        return acc;
      },
      { 'video/mp4': [], 'video/webm': [], 'video/x-matroska': [] }
    );

    if (availableMimeTypes['video/mp4'].length > 0) {
      return { mime: 'video/mp4', ext: 'mp4' };
    } else if (availableMimeTypes['video/webm'].length > 0) {
      return { mime: 'video/webm', ext: 'webm' };
    } else if (availableMimeTypes['video/x-matroska'].length > 0) {
      return { mime: 'video/x-matroska', ext: 'mkv' };
    } else {
      throw new Error('No supported mime types found');
    }
  }

  // Method to start processing queue
  public async process() {
    this.stopInterval();
    const chunks = this.rawQueue.map((b, i) =>
      new ChunkPayload(this.question_id, ChunkTypeEnum.CHUNK, b.elapsed, b.blob).setPart(i + 1)
    );
    this.startChunk = new ChunkPayload(this.question_id, ChunkTypeEnum.START, 0).setPart(0);
    this.endChunk = new ChunkPayload(
      this.question_id,
      ChunkTypeEnum.END,
      this.getElapsedTime()
    ).setPart(chunks.length + 1);
    this.processedQueue = [this.startChunk, ...chunks, this.endChunk];
  }

  // Method to abort the queue
  public abort() {
    this.stopInterval();
    this.rawQueue = [];
    this.processedQueue = [
      new ChunkPayload(this.question_id, ChunkTypeEnum.ABORTED, this.getQueueSize()).setPart(
        this.getQueueSize()
      ),
    ];
  }

  // Method to get the next chunk to send
  public getNextChunk(): ChunkPayload | undefined {
    return this.processedQueue.find((c) => !c.sent_at);
  }

  // Method to send a chunk
  public setSent(c: ChunkPayload): void {
    c.setSent();
  }

  // Method to get the queue size
  public getQueueSize(): number {
    return this.processedQueue.length;
  }
}
