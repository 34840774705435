import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { GroupType } from '../../../interfaces/assessment';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import ExamDurationIcon from '../../../shared/components/Icons/ExamDurationIcon';
import MixedCustomQuestions from '../../../shared/components/Icons/MixedCustomQuestions';
import QuestionnaireIcon from '../../../shared/components/Icons/QuestionnaireIcon';
import QuestionsNumberIcon from '../../../shared/components/Icons/QuestionsNumberIcon';
import VideoInterviewIcon from '../../../shared/components/Icons/VideoInterviewIcon';
import VideoRetakeIcon from '../../../shared/components/Icons/VideoRetakeIcon';
import AudioInterviewIcon from '../../../shared/components/Icons/AudioInterviewIcon';

interface Props {
  type: GroupType;
  duration?: number;
  questions: number;
  retakes?: number;
  isAudio?: boolean;
}

const BriefingBox = ({ type, duration, questions, retakes, isAudio }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="my-4 flex w-full flex-row flex-wrap items-stretch justify-between gap-2 lg:my-6 lg:gap-6 lg:px-0">
      {/* Type box */}
      <div
        className={clsx(
          'flex basis-full items-center justify-start gap-4 rounded-lg bg-[#F8F8F8] p-4 shadow-cust-2 lg:inline-flex',
          {
            'lg:basis-auto': ['custom-questionnaires', 'soft-skills'].includes(type),
            'lg:flex-1': type !== 'custom-questionnaires',
          }
        )}
      >
        {type === 'soft-skills' && (isAudio ? <AudioInterviewIcon /> : <VideoInterviewIcon />)}
        {type === 'hard-skills' && <QuestionnaireIcon />}
        {type === 'custom-questions' && <MixedCustomQuestions />}
        {type === 'filter-questions' && <QuestionnaireIcon />}
        {type === 'custom-questionnaires' && <QuestionnaireIcon />}
        <div>
          <HeadingText
            className="uppercase"
            variant="secondary-small"
          >
            {t('briefingBox.boxType.method')}
          </HeadingText>
          <HeadingText
            className="font-bold"
            variant="primary-small"
          >
            {type === 'soft-skills' &&
              (isAudio
                ? t('briefingBox.boxType.audiointerview.title')
                : t('briefingBox.boxType.videointerview.title'))}
            {type === 'custom-questions' && t('briefingBox.boxType.customQuestions.title')}
            {type === 'hard-skills' && t('briefingBox.boxType.questionnaire.title')}
            {type === 'filter-questions' && t('briefingBox.boxType.questionnaire.title')}
            {type === 'custom-questionnaires' && t('briefingBox.boxType.customQuestionnaire.title')}
          </HeadingText>
        </div>
      </div>
      {/* Number of questions box */}
      <div
        className={clsx(
          'flex basis-full items-center justify-start gap-4 rounded-lg bg-[#F8F8F8] p-4 shadow-cust-2 lg:inline-flex',
          {
            'lg:basis-auto': ['custom-questionnaires', 'soft-skills'].includes(type),
            'lg:flex-1': type !== 'custom-questionnaires',
          }
        )}
      >
        <QuestionsNumberIcon />
        <div>
          <HeadingText
            className="uppercase"
            variant="secondary-small"
          >
            {t('briefingBox.boxQuestions.number')}
          </HeadingText>
          <HeadingText
            className="font-bold"
            variant="primary-small"
          >
            {questions}
          </HeadingText>
        </div>
      </div>
      {/* Duration box */}
      {duration && duration > 0 && (
        <div
          className={clsx(
            'flex basis-full items-center justify-start gap-4 rounded-lg bg-[#F8F8F8] p-4 shadow-cust-2 lg:inline-flex',
            {
              'lg:basis-auto': ['custom-questionnaires', 'soft-skills'].includes(type),
              'lg:flex-1': type !== 'custom-questionnaires',
            }
          )}
        >
          <ExamDurationIcon />
          <div>
            <HeadingText
              className="uppercase"
              variant="secondary-small"
            >
              {t('briefingBox.boxDuration.title')}
            </HeadingText>
            <HeadingText
              className="font-bold"
              variant="primary-small"
            >
              {Math.round(duration / 60)} {t('briefingBox.boxDuration.minutes')}
            </HeadingText>
          </div>
        </div>
      )}
      {/* Retake info box */}
      {type === 'soft-skills' && retakes && (
        <div
          className={clsx(
            'flex basis-full items-center justify-start gap-4 rounded-lg bg-[#F8F8F8] p-4 shadow-cust-2 lg:inline-flex lg:flex-1'
          )}
        >
          <VideoRetakeIcon />
          <div>
            <HeadingText
              className="uppercase"
              variant="secondary-small"
            >
              {t('briefingBox.boxRetake.title')}
            </HeadingText>
            <HeadingText
              className="font-bold"
              variant="primary-small"
            >
              {retakes} {t('briefingBox.boxRetake.subtitle')}
            </HeadingText>
          </div>
        </div>
      )}
    </div>
  );
};

export default BriefingBox;
