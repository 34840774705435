import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { SoftSkillQuestionStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import ConnectionStatus from '../../../shared/components/ConnectionStatus/ConnectionStatus';
import ArrowRotateLeftIcon from '../../../shared/components/Icons/ArrowRotateLeftIcon';
import CheckmarkIcon from '../../../shared/components/Icons/CheckmarkIcon';
import InfoIcon from '../../../shared/components/Icons/InfoIcon';
import PlayLittleIcon from '../../../shared/components/Icons/PlayLittleIcon';

interface Props {
  counters?: { current: number; total: number };
  connected: boolean;
  currentStep: SoftSkillQuestionStep;
  url: string | undefined;
  interviewRetake: () => void;
  submitVideoInterview: () => void;
}

export default function VideoPreview({
  counters,
  connected,
  currentStep,
  url,
  interviewRetake,
  submitVideoInterview,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className="m-[auto] flex h-full w-full flex-col items-center justify-center">
      <div
        className={clsx(
          'relative mx-auto flex h-full w-full flex-col items-center gap-6 overflow-hidden bg-white p-6',
          'lg:h-auto lg:max-w-[1024px] lg:rounded-lg lg:shadow-cust-1'
        )}
      >
        <div className="flex w-full items-center justify-between">
          {/* Question counter */}
          <div className="text-lg font-bold">
            {t('videoInterview.counter.question')} {counters && counters.current + 1}{' '}
            {t('videoInterview.counter.of')} {counters && counters.total}
          </div>
          <div className="flex items-center gap-2">
            {/* Connection icon */}
            <ConnectionStatus
              type="icon"
              connected={connected}
            />
            <span className={clsx('hidden text-sm text-[#666666]', 'lg:block')}>
              {connected
                ? t('videoPreview.connectionIcon.connected')
                : t('videoPreview.connectionIcon.disconnected')}
            </span>
          </div>
        </div>
        {/* Video Player */}
        <video
          className="h-auto w-[40rem] rounded-lg"
          src={url}
          controls
        />
        {/* Footer */}
        <div
          className={clsx(
            'absolute bottom-0 flex w-full flex-col items-center justify-between',
            'lg:relative'
          )}
        >
          <div className="mb-4 flex items-center justify-center gap-2 lg:mb-0 lg:hidden">
            <PlayLittleIcon color="#1F883D" />
            <span className="text-sm text-[#1F883D]">{t('videoPreview.replay.status')}</span>
          </div>
          {/* Retake info banner */}
          <span
            className={clsx(
              'mb-6 flex w-full max-w-[350px] justify-center gap-2 rounded-lg bg-[#FFF8C5] px-8 py-2 font-bold text-[#AD4E00]',
              'lg:max-w-none'
            )}
          >
            <InfoIcon color="#AD4E00" />
            {t('videoPreview.attempt.banner', {
              count:
                (currentStep && currentStep.data.retake && currentStep.data.retake.current + 1) ||
                0,
            })}
          </span>
          <div
            className={clsx(
              'flex w-full flex-col items-center border-t border-[#DDDDDD] p-4',
              'lg:flex-row'
            )}
          >
            <div className="hidden w-full lg:flex">
              <div className="flex w-full flex-1 items-center justify-start">
                <PlayLittleIcon color="#1F883D" />
                <span className="ml-2 inline-flex text-sm text-[#1F883D]">
                  {t('videoPreview.replay.status')}
                </span>
              </div>
            </div>

            <div
              className={clsx('flex w-full flex-col items-center', 'lg:flex-row lg:justify-end')}
            >
              <Button
                className={clsx(
                  'secondary mb-2 gap-2',
                  'md:w-full md:max-w-[350px]',
                  'lg:mb-0 lg:mr-6 lg:w-auto'
                )}
                onClick={interviewRetake}
              >
                <ArrowRotateLeftIcon color="#111" />
                {t('videoPreview.button.retry')}
              </Button>
              <Button
                className={clsx(
                  'gap-2 bg-[#038939] text-white',
                  'md:w-full md:max-w-[350px]',
                  'lg:mb-0 lg:w-auto'
                )}
                onClick={submitVideoInterview}
              >
                <CheckmarkIcon color="#FFF" />
                {t('videoPreview.button.confirm')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
