import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { SoftSkillQuestionStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import ConnectionStatus from '../../../shared/components/ConnectionStatus/ConnectionStatus';
import ArrowRotateLeftIcon from '../../../shared/components/Icons/ArrowRotateLeftIcon';
import { convertHexToRGBA } from '../../../shared/utils/colors';
import { getCompanyBrandCustomizations } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import ConnectionIcons from '../../VideoInterview/components/ConnectionIcons';

interface Props {
  counters?: { current: number; total: number };
  connected: boolean;
  currentStep: SoftSkillQuestionStep;
  url: string | undefined;
  interviewRetake: () => void;
  submitVideoInterview: () => void;
}

export default function AudioPreview({
  counters,
  connected,
  currentStep,
  url,
  interviewRetake,
  submitVideoInterview,
}: Props) {
  const { t } = useTranslation();

  // Selectors
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  // Computed
  const backgroundColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 5);

  return (
    <div className="m-[auto] flex h-full w-full flex-col items-center justify-center">
      {/* Connection status Banner */}
      <ConnectionStatus
        type="banner"
        connected={connected}
      />
      <div
        style={{ backgroundColor }}
        className={clsx(
          'relative mx-auto flex h-full w-full flex-col items-stretch justify-between gap-32 overflow-hidden p-2',
          'lg:flex-row lg:p-8 xl:p-16'
        )}
      >
        {/* Right column desktop - question container */}
        <div className="w-full lg:w-[50%]">
          <div className="pb-6 text-base font-[600] text-[#525252] lg:pb-2">
            {t('videoInterview.counter.question')} {counters && counters.current + 1}{' '}
            {t('videoInterview.counter.of')} {counters && counters.total}
          </div>

          {/* Question text */}
          <div className={clsx('font-regular text-xl text-[#111]', 'lg:text-4xl')}>
            {currentStep.data.question.text}
          </div>
        </div>

        {/* Left column desktop - interview container */}
        <div
          className={clsx(
            'relative flex h-auto flex-col items-stretch justify-between gap-12 rounded-xl bg-white p-4 shadow-cust-1',
            'lg:h-full lg:w-[50%] lg:p-6',
            'xl:p-8'
          )}
        >
          <div className="flex justify-between text-base font-semibold text-[#111]">
            <span> {t('audioInterview.answer.title')}</span>
            <div className="flex">
              {currentStep.data.retake && currentStep.data.retake.limit > 1 && (
                <span className="pr-2">
                  {t('audioInterview.answer.retake')}{' '}
                  {`${currentStep.data.retake.current + 1}/${currentStep.data.retake.limit}`}
                </span>
              )}
              <ConnectionIcons
                isAudioOnly
                onlyWifi
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <audio
              className="w-full"
              src={url}
              controls
            ></audio>
          </div>

          {/* Footer */}
          <div className={clsx('flex w-full flex-col justify-center gap-2', 'lg:flex-row')}>
            <Button
              className={clsx('secondary lg:w-30 flex w-full !max-w-none gap-2')}
              onClick={interviewRetake}
            >
              <ArrowRotateLeftIcon color="#111" />
              {t('audioPreview.button.retry')}{' '}
              {currentStep.data.retake && `(${currentStep.data.retake.current + 1})`}
            </Button>
            <Button
              className={clsx('default lg:w-30 flex w-full !max-w-none gap-2')}
              onClick={submitVideoInterview}
            >
              {t('audioPreview.button.confirm')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
