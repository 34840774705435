import clsx from 'clsx';
import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/components/Button/Button';
import ConnectionStatus from '../../shared/components/ConnectionStatus/ConnectionStatus';
import RecLittleIcon from '../../shared/components/Icons/RecLittleIcon';
import { convertHexToRGBA } from '../../shared/utils/colors';
import { getCount } from '../../shared/utils/getCountDown';
import { getCompanyBrandCustomizations } from '../../store/exam/selectors';
import { useAppSelector } from '../../store/store';
import AudioWave from '../VideoInterview/components/AudioWave';
import ConnectionIcons from '../VideoInterview/components/ConnectionIcons';
import CountDownOverlay from '../VideoInterview/components/CountDownOverlay';

interface Props {
  player: MutableRefObject<HTMLAudioElement | null>;
  connected: boolean;
  recording: boolean;
  enableStop: boolean;
  completed: boolean;
  countdown: number;
  showCountdown: boolean;
  timer: string;
  stream: MediaStream | undefined;
  duration: number;
  recordingHandler: () => void;
}

export default function InterviewSimulationAudio({
  player,
  connected,
  recording,
  enableStop,
  completed,
  recordingHandler,
  countdown,
  showCountdown,
  timer,
  stream,
  duration,
}: Props) {
  const { t } = useTranslation();

  // Selectors
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  // Computed
  const backgroundColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 5);
  const accentColor = brandCustomization?.['accent-color'];

  const interviewDuration = getCount(duration);
  return (
    <div className="m-[auto] flex h-[100vh] w-full flex-col items-center justify-center">
      {/* Connection status Banner */}
      <ConnectionStatus
        type="banner"
        connected={connected}
      />
      <div
        style={{ backgroundColor }}
        className={clsx(
          'm-[0 auto] relative flex h-full w-full flex-col items-stretch justify-between gap-32 overflow-auto p-2',
          'lg:flex-row lg:p-8 xl:p-16'
        )}
      >
        {/* Right column desktop - question container */}
        <div className="w-full lg:w-[50%]">
          <div className="pb-6 text-base font-[600] text-[#525252] lg:pb-2">
            {t('videoInterview.counter.question')} 1 {t('videoInterview.counter.of')} 1
          </div>

          {/* Question text */}
          <div className={clsx('font-regular text-xl text-[#111]', 'lg:text-4xl')}>
            {t('interviewSimulation.question.text')}
          </div>
        </div>

        {/* Left column desktop - interview container */}
        <div className="relative flex h-auto flex-col items-stretch justify-between rounded-xl bg-white shadow-cust-1 lg:h-full lg:w-[50%]">
          <div
            style={{ backgroundColor: accentColor }}
            className="rounded-t-xl p-4 text-center text-lg text-white"
          >
            <strong>{t('interviewSimulation.banner.title')}</strong>
            <br></br>
            <span className="text-base">{t('interviewSimulation.banner.subtitle')}</span>
          </div>
          <div className="flex h-full flex-col items-stretch justify-between p-4 lg:p-6 lg:pt-4 xl:p-8 xl:pt-4">
            {/* Countdown overlay */}
            <CountDownOverlay
              currentStep={null}
              showCountdown={showCountdown}
              countdown={countdown}
              isAudio
            />

            <div className="flex justify-between text-base font-[600] text-[#111]">
              <span> {t('audioInterview.answer.title')}</span>
              <div className="flex">
                <ConnectionIcons isAudioOnly />
              </div>
            </div>

            <div className="flex flex-col items-center justify-center">
              <audio
                ref={player}
                muted={true}
                style={{ display: 'none' }}
              ></audio>
              <AudioWave
                audioStream={stream}
                color={accentColor || '#038939'}
              />
              {/* Rec timer */}

              <span className="mt-4 text-2xl font-[700] text-[#111] lg:mt-8 lg:text-5xl">
                {recording ? `${timer}/${interviewDuration}` : `00:00/${interviewDuration}`}
              </span>
              {recording && (
                <span className="mt-4 flex items-center justify-center gap-2 font-bold text-[#CF222E]">
                  <RecLittleIcon color="#CF222E" />
                  {enableStop ? t('videoInterview.recording') : t('videoInterview.min.recording')}
                </span>
              )}
            </div>

            {/* Rec Button */}
            <div className="flex w-full justify-center">
              <Button
                className={clsx('default lg:w-30 mt-6 flex w-full !max-w-none gap-2')}
                disabled={completed || (!completed && recording && !enableStop)}
                onClick={
                  (!completed && !recording) || (!completed && recording && enableStop)
                    ? recordingHandler
                    : () => null
                }
              >
                {/* Rec Button text */}
                {!completed && !recording && t('videoInterview.button.record')}
                {!completed && recording && t('videoInterview.button.recording')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
