import clsx from 'clsx';
import { HTMLAttributes } from 'react';

type Variants = 'primary' | 'primary-small' | 'secondary' | 'secondary-small';

interface TextProps extends HTMLAttributes<HTMLDivElement> {
  variant?: Variants;
  isTitle?: boolean;
}

const HeadingText = ({ variant = 'primary', children, isTitle, ...props }: TextProps) => {
  const variants: Record<Variants, string> = {
    primary: 'text-xl text-[#111111]',
    'primary-small': 'text-md text-[#111111]',
    secondary: 'text-sm text-[#626262]',
    'secondary-small': 'text-xs text-[#626262]',
  };

  return isTitle ? (
    <h1
      {...props}
      className={clsx(variants[variant], props.className)}
    >
      {children}
    </h1>
  ) : (
    <h2
      {...props}
      className={clsx(variants[variant], props.className)}
    >
      {children}
    </h2>
  );
};

export default HeadingText;
