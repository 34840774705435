import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../../App';
import { SoftSkillQuestionStep } from '../../../interfaces/assessment';
import { actions as examActions } from '../../../store/exam/reducer';
import {
  getCurrentSoftSkillQuestionCounters,
  getCurrentStep,
  getJobPostData,
  getSoftSkillQuestionRetakeDetails,
} from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import AudioPreview from './AudioPreview';
import VideoPreview from './VideoPreview';

export default function Preview() {
  // Hooks
  const { socket, connected } = useSocketInstance();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Selectors
  const currentStep = useAppSelector(getCurrentStep) as SoftSkillQuestionStep | null;
  const jobPostData = useAppSelector(getJobPostData);
  const counters = useAppSelector(getCurrentSoftSkillQuestionCounters);
  const retakeDetails = useAppSelector(getSoftSkillQuestionRetakeDetails);
  // Early failure for undefined interview
  if (!currentStep) {
    // TODO: Add a better component
    return <div className="flex h-40 items-center">Something went wrong</div>;
  }

  // Handlers
  const submitVideoInterview = (): void => {
    socket.emit('submit-soft-skill-question-interview', {
      job_post_alias,
      application_alias,
      question_id: currentStep.data.question.id,
    });
    navigate(`/${job_post_alias}/${application_alias}/soft-skill-interview-done`, {
      replace: true,
    });
  };

  const interviewRetake = (): void => {
    dispatch(examActions.INCREASE_RETAKE_COUNT());
    navigate(`/${job_post_alias}/${application_alias}/soft-skill-interview`, { replace: true });
  };

  const isAudioOnly = jobPostData?.soft_skill_type === 'audio';

  return isAudioOnly ? (
    <AudioPreview
      counters={counters}
      connected={connected}
      currentStep={currentStep}
      url={retakeDetails?.temporary_url}
      interviewRetake={interviewRetake}
      submitVideoInterview={submitVideoInterview}
    />
  ) : (
    <VideoPreview
      counters={counters}
      connected={connected}
      currentStep={currentStep}
      url={retakeDetails?.temporary_url}
      interviewRetake={interviewRetake}
      submitVideoInterview={submitVideoInterview}
    />
  );
}
