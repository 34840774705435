import { useRef } from 'react';
import { MIMETYPE_LIST, MimeTypes, MimeTypesWithCodec } from '../../shared/utils/mimetypes';

interface HookReturnProps {
  mediaRecorder: MediaRecorder | null;
  initRecorder: (
    mediaStream: MediaStream,
    eventListeners: InitRecorderEventListeners
  ) => MediaRecorder | null;
  startRecording: (timeslice?: number) => void;
  stopRecording: () => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
}

interface InitRecorderEventListeners {
  onStart?: () => void;
  onStop?: () => void;
  onError?: (e: ErrorEvent) => void;
  onDataAvailable?: (e: BlobEvent) => void;
}

type FileExtension = 'mp4' | 'webm' | 'mkv';

type DetectedMimeType = { mime: MimeTypes; ext: FileExtension };

const DEFAULT_TIMESLICE = 1000;

const useMediaRecorder = (): HookReturnProps => {
  // Refs
  const mediaRecorder = useRef<MediaRecorder | null>(null);

  /**
   * Handlers
   */
  // Get the supported mime type for the current browser
  const getMimeType = (): DetectedMimeType => {
    // Filter out unsupported mime types
    const supportedMimeTypes: MimeTypesWithCodec[] = MIMETYPE_LIST.filter((mimeType) =>
      MediaRecorder.isTypeSupported(mimeType)
    );

    // Group the supported mime types by type
    const availableMimeTypes = supportedMimeTypes.reduce(
      (acc: Record<MimeTypes, string[]>, val) => {
        const type: MimeTypes = val.split(';')[0] as MimeTypes;
        acc[type] = [...acc[type], val];
        return acc;
      },
      { 'video/mp4': [], 'video/webm': [], 'video/x-matroska': [] }
    );

    // Return the first supported mime type or throw an error if none are found
    if (availableMimeTypes['video/mp4'].length > 0) {
      return { mime: 'video/mp4', ext: 'mp4' };
    } else if (availableMimeTypes['video/webm'].length > 0) {
      return { mime: 'video/webm', ext: 'webm' };
    } else if (availableMimeTypes['video/x-matroska'].length > 0) {
      return { mime: 'video/x-matroska', ext: 'mkv' };
    } else {
      throw new Error('No supported mime types found');
    }
  };

  // Handle the initialization of the media recorder
  const initRecorder = (
    mediaStream: MediaStream,
    {
      onStart = () => {},
      onStop = () => {},
      onError = (_: ErrorEvent) => {},
      onDataAvailable = (_: BlobEvent) => {},
    }: InitRecorderEventListeners
  ): MediaRecorder | null => {
    let mimeType: DetectedMimeType | undefined;
    try {
      mimeType = getMimeType();
    } catch (e) {
      console.error('No supported mime types found:', e);
      /**
       * TODO: qui sarebbe sensato redirezionare l'utente ad una pagina
       * in cui gli viene spiegato che il suo browser non supporta la registrazione video/audio
       * e che deve passare ad un browser più moderno e che supporti le API necessarie
       */
      return null;
    }
    const recorder: MediaRecorder = new MediaRecorder(mediaStream, { mimeType: mimeType.mime });
    recorder.onstart = onStart;
    recorder.onstop = onStop;
    recorder.onerror = onError;
    recorder.ondataavailable = onDataAvailable;
    mediaRecorder.current = recorder;
    return recorder;
  };

  // Handle the start of the recording
  const startRecording = (timeslice: number = DEFAULT_TIMESLICE): void => {
    if (mediaRecorder && mediaRecorder.current && mediaRecorder.current.state === 'inactive') {
      mediaRecorder.current.start(timeslice);
    }
  };

  // Handle the stop of the recording
  const stopRecording = (): void => {
    if (mediaRecorder && mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
      // We delay the unbinding of the event listeners in order to let them execute properly the last time
      setTimeout(() => {
        if (mediaRecorder && mediaRecorder.current) {
          mediaRecorder.current.onstart = null;
          mediaRecorder.current.onstop = null;
          mediaRecorder.current.onerror = null;
          mediaRecorder.current.ondataavailable = null;
          mediaRecorder.current = null;
        }
      }, 1000);
    }
  };

  // Handle the pause of the recording
  const pauseRecording = (): void => {
    if (mediaRecorder && mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.pause();
    }
  };

  // Handle the resume of the recording
  const resumeRecording = (): void => {
    if (mediaRecorder && mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      mediaRecorder.current.resume();
    }
  };

  // Exposed props
  return {
    mediaRecorder: mediaRecorder.current,
    initRecorder,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  };
};

export default useMediaRecorder;
