import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../App';
import { SoftSkillQuestionStep, VideoStep } from '../../interfaces/assessment';
import { InterviewSocketEvent, SocketEventEnum } from '../../interfaces/socket';
import AudioAnalyserDialog from '../../shared/components/Dialog/contents/AudioAnalyserDialog';
import Dialog from '../../shared/components/Dialog/Dialog';
import useAudioAnalyser from '../../shared/hooks/useAudioAnalyser';
import useErrorHandler, { ErrorCodes } from '../../shared/hooks/useErrorHandler';
import { ChunkPayload, ChunksQueue } from '../../shared/socket/chunkPayload';
import { convertHexToRGBA } from '../../shared/utils/colors';
import { getCount, getCountDown } from '../../shared/utils/getCountDown';
import { actions } from '../../store/exam/reducer';
import {
  getAuthenticated,
  getCompanyBrandCustomizations,
  getCompletedChunks,
  getCurrentQuestionId,
  getCurrentSoftSkillQuestionCounters,
  getCurrentStep,
  getJobPostData,
  getPresentationInterviewCompletedChunks,
} from '../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import AudioInterview from '../VideoInterview/components/AudioInterview';
import VideoInterview from '../VideoInterview/components/VideoInterview';
import useMediaRecorder from './useMediaRecorder';
import useMediaStream from './useMediaStream';

const DURATION: number = Number(import.meta.env.VITE_APP_INTERVIEW_DURATION);
const MIN_INTERVIEW_DURATION: number = Number(import.meta.env.VITE_APP_MIN_INTERVIEW_DURATION);
const TICK_INTERVAL: number = Number(import.meta.env.VITE_NO_AUDIO_CHECK_TICK_INTERVAL);
const LIMIT: number = Number(import.meta.env.VITE_NO_AUDIO_CHECK_LIMIT);
const THRESHOLD: number = Number(import.meta.env.VITE_NO_AUDIO_CHECK_THRESHOLD);
const ALLOWED_STEP_TYPES: string[] = ['soft-skill-question', 'video'];

interface Props {
  socketEvent: InterviewSocketEvent;
}

const Interview = ({ socketEvent }: Props) => {
  // Hooks
  const { socket, connected } = useSocketInstance();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mediaStream, getStream, stopStream } = useMediaStream();
  const { initRecorder, startRecording, stopRecording } = useMediaRecorder();
  const { startAnalyser, stopAnalyser } = useAudioAnalyser(
    TICK_INTERVAL, // tick interval (in milliseconds)
    LIMIT, // no-audio limit (in milliseconds)
    THRESHOLD, // volume threshold (volume can be between 0.xx and more)
    () => {
      chunksQueue.current.abort();
      onStop();
      setIsDialogOpen(true); // Open dialog
      const chunk = chunksQueue.current.getNextChunk();
      if (chunk) {
        sendChunk(chunk);
      }
    }
  );
  const { handleError } = useErrorHandler(socket);

  // Refs
  const videoPlayerRef = useRef<HTMLVideoElement | null>(null);
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const elapsed = useRef<number>(0);
  const chunksQueue = useRef<ChunksQueue>(new ChunksQueue()); // This stores all the chunks that are not sent yet
  const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
  const retry = useRef<{ delay: number; executing: boolean }>({ delay: 1, executing: false }); // Variable used to understand if a chunk is retrying in its delay range
  const contentLength = useRef<number | null>(null);

  // Selectors
  const interview_completed_chunks = useAppSelector(getCompletedChunks);
  const presentation_completed_chunks = useAppSelector(getPresentationInterviewCompletedChunks);
  const currentStep = useAppSelector(getCurrentStep) as SoftSkillQuestionStep | VideoStep | null;
  const counters = useAppSelector(getCurrentSoftSkillQuestionCounters);
  const jobPostData = useAppSelector(getJobPostData);
  const isAuthenticated = useAppSelector(getAuthenticated);
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);
  const question_id = useAppSelector(getCurrentQuestionId);

  // Computed
  const backgroundColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 5);
  const borderColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 25);
  const accentColor = brandCustomization?.['accent-color'];
  const isAudioOnly =
    jobPostData?.soft_skill_type === 'audio' && currentStep?.group !== 'custom-questions';
  const completed_chunks =
    currentStep?.type === 'video' ? presentation_completed_chunks : interview_completed_chunks;
  const redirect_url =
    currentStep?.type === 'video'
      ? `/${job_post_alias}/${application_alias}/custom-interview-done`
      : `/${job_post_alias}/${application_alias}/soft-skill-interview-done`;

  // States
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState<number>(0);
  const [showCountdown, setShowCountdown] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(5);
  const [recording, setRecording] = useState<boolean>(false);
  const [timer, setTimer] = useState<string>(getCountDown(DURATION, elapsed?.current));
  const [audioTimer, setAudioTimer] = useState<string>(getCount(elapsed?.current)); // This gets formatted to 03:00 string.
  const [completed, setCompleted] = useState<boolean>(false);
  const [enableStop, setEnableStop] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [semaphore, setSemaphore] = useState<boolean>(false);

  /**
   * Handlers
   */
  // Handle the start event
  const onStart = (): void => {
    if (!question_id) {
      handleError(ErrorCodes.INTERVIEW_START, { question_id });
      return;
    }
    chunksQueue.current.start(question_id);
    startAnalyser();
  };

  // Handle the error event
  const onError = (e: ErrorEvent): void => {
    console.error('Error:', e);
    handleError(ErrorCodes.INTERVIEW_EVENT_ERROR, { question_id });
  };

  // Handle the data available event
  const onDataAvailable = (e: BlobEvent): void => chunksQueue.current.addChunk(e.data);

  // Handle the initialization of the stream
  const initStream = (
    mediaStream: MediaStream,
    ref: MutableRefObject<HTMLVideoElement | HTMLAudioElement | null>
  ): void => {
    if (ref && ref.current) {
      if (!('srcObject' in ref.current)) {
        // TODO: Fallback for older browsers
        handleError(ErrorCodes.INTERVIEW_INIT_STREAM, { question_id });
      }
      ref.current.srcObject = mediaStream;
      ref.current.onloadedmetadata = () => handleStream(mediaStream, ref);
    }
  };

  const recordingHandler = async (): Promise<void> => {
    if (!recording) {
      setShowCountdown(true);
    } else {
      onStop();
    }
  };

  const sendChunk = useCallback(
    async (chunk: ChunkPayload) => {
      if (!chunk || !socketEvent) {
        return;
      }
      if (socket.connected && isAuthenticated && !semaphore && !retry.current.executing) {
        retry.current.executing = true;
        setSemaphore(true);
        try {
          socket.emit(
            socketEvent,
            chunk,
            async (
              response: { success: boolean; error: string | null; code: string | null } // Sent by our server
            ) => {
              const { error } = response;
              // Server error. Handled by our backend
              if (error) {
                setSemaphore(false);
                retry.current.executing = false;
                handleError(ErrorCodes.INTERVIEW_SEND_CHUNK_BACKEND_ERROR, { question_id });
                return;
              }

              chunksQueue.current.setSent(chunk);
              retry.current.delay = 1;
              retry.current.executing = false;
              setSemaphore(false);
            }
          );
        } catch (e) {
          handleError(ErrorCodes.INTERVIEW_SEND_CHUNK, { question_id }, e as Error);
        }
      }
    },
    [handleError, isAuthenticated, question_id, semaphore, socket, socketEvent]
  );

  // Handle the stop event
  const onStop = useCallback(async (): Promise<void> => {
    if (contentLength.current === null) {
      stopRecording();
      setRecording(false);
      stopStream();
      stopAnalyser();
      setSubmitted(true);
      await chunksQueue.current.process();
      const length = chunksQueue.current.getQueueSize();
      contentLength.current = length;
      socket.emit(
        SocketEventEnum.RECORDING_CONTENT_LENGTH,
        { question_id, length },
        (response: { success: boolean; error: string | null; code: string | null }) => {
          const { error, code } = response;
          if (error) {
            console.error(
              `An error with internal code ${code} occurred while sending the content length: ${error}`
            );
            handleError(ErrorCodes.INTERVIEW_SEND_QUEUE_LENGTH_ERROR, { question_id });
            return;
          }
          setCompleted(true);
        }
      );
    }
  }, [handleError, question_id, socket, stopAnalyser, stopRecording, stopStream]);

  // Handle the start of the stream
  const handleStream = (
    mediaStream: MediaStream,
    ref: MutableRefObject<HTMLVideoElement | HTMLAudioElement | null>
  ) => {
    const recorder = initRecorder(mediaStream, {
      onStart,
      onStop,
      onError,
      onDataAvailable,
    });
    if (!recorder) {
      handleError(ErrorCodes.INTERVIEW_INIT_RECORDER, { question_id });
    }
    (ref.current as HTMLVideoElement).play();
  };

  // Effects
  useEffect(() => {
    if (!semaphore && completed) {
      const chunk = chunksQueue.current.getNextChunk();
      if (chunk) {
        sendChunk(chunk);
      }
    }
  }, [semaphore, completed, sendChunk]);

  useEffect(() => {
    if (typeof isAudioOnly === 'boolean') {
      getStream({ video: !isAudioOnly, audio: true }).then((mediaStream) =>
        initStream(mediaStream, isAudioOnly ? audioPlayerRef : videoPlayerRef)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAudioOnly]);

  // Handle recording elapsed time
  useEffect(() => {
    let recordingInterval: ReturnType<typeof setInterval> | undefined;
    if (recording) {
      recordingInterval = setInterval(() => {
        elapsed.current = elapsed.current + 1;
        setTimer(getCountDown(DURATION, elapsed.current));
        setAudioTimer(getCount(elapsed.current));
        if (elapsed.current >= MIN_INTERVIEW_DURATION && !enableStop) {
          setEnableStop(true);
        }
        if (elapsed.current > DURATION) {
          onStop().then(() => clearInterval(recordingInterval));
        }
      }, 1000);
    } else {
      clearInterval(recordingInterval);
    }
    return () => {
      clearInterval(recordingInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableStop, recording]);

  // Handle countdown when recording button is pressed
  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (showCountdown) {
      interval = setInterval(() => {
        setCountdown((state: number) => state - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [showCountdown]);

  // Handle countdown expiration
  useEffect(() => {
    if (countdown === 0 && !completed && !recording) {
      setRecording(true);
      setShowCountdown(false);
      startRecording();
      dispatch(actions.VIDEO_INTERVIEW_START());
    }
  }, [countdown, completed, recording, startRecording, dispatch]);

  useEffect(() => {
    const total_chunks = chunksQueue.current.getQueueSize();
    if (total_chunks > 0) {
      let percentage: number =
        100 - Math.round(((total_chunks - completed_chunks) / total_chunks) * 100);
      // NOTE: This is a temporary hack to avoid the percentage to go over 100
      if (percentage > 100) {
        percentage = 100;
      }
      setCompletionPercentage(percentage);
      // Check if it has been completed
      if (percentage === 100) {
        // Check whether the soft skill has a retake and user has reached limit or the soft skills has no retake. Then redirect
        if (
          (currentStep &&
            'retake' in currentStep.data &&
            currentStep.data.retake &&
            currentStep.data.retake.current + 1 === currentStep.data.retake.limit) ||
          (currentStep && (!('retake' in currentStep.data) || !currentStep.data.retake))
        ) {
          clearInterval(interval.current);
          interval.current = undefined;
          const t = setTimeout(() => {
            clearTimeout(t);
            navigate(redirect_url, { replace: true });
          }, 1000);
        }
      }
    }
  }, [completed_chunks, currentStep, navigate, redirect_url]);

  if (!currentStep || !ALLOWED_STEP_TYPES.includes(currentStep.type) || !question_id) {
    return null;
  }

  if (isDialogOpen) {
    return (
      <Dialog isOpen={isDialogOpen}>
        <AudioAnalyserDialog
          onClick={() => {
            // Reset test device done
            dispatch(actions.TEST_DEVICE_DONE(false));
            navigate(`/${job_post_alias}/${application_alias}/test-devices`, {
              replace: true,
              state: {
                from: 'soft-skill-interview',
              },
            });
          }}
        />
      </Dialog>
    );
  }

  if (isAudioOnly) {
    return (
      <AudioInterview
        accentColor={accentColor}
        backgroundColor={backgroundColor}
        duration={DURATION}
        completed={completed}
        completionPercentage={completionPercentage}
        connected={connected}
        countdown={countdown}
        counters={counters}
        currentStep={currentStep as SoftSkillQuestionStep}
        enableStop={enableStop}
        player={audioPlayerRef}
        recording={recording}
        showCountdown={showCountdown}
        submitted={submitted}
        timer={audioTimer}
        stream={mediaStream}
        onRecording={recordingHandler}
      />
    );
  }

  return (
    <VideoInterview
      accentColor={accentColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      completed={completed}
      completionPercentage={completionPercentage}
      connected={connected}
      countdown={countdown}
      counters={currentStep.type === 'video' ? { current: 0, total: 1 } : counters}
      currentStep={currentStep}
      enableStop={enableStop}
      player={videoPlayerRef}
      recording={recording}
      showCountdown={showCountdown}
      submitted={submitted}
      timer={timer}
      onRecording={recordingHandler}
    />
  );
};

export default Interview;
