import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HeadingText from '../../shared/components/HeadingText/HeadingText';
import { SupportEmail } from '../../shared/components/SupportEmail/SupportEmail';
import { actions as examActions } from '../../store/exam/reducer';
import { useAppDispatch } from '../../store/store';

const DeploymentInProgress = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (!timeout) {
      timeout = setTimeout(() => {
        dispatch(examActions.DISABLE_LOADING());
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [dispatch]);

  return (
    <div className="space-between flex h-full w-full flex-col">
      <div
        className={clsx(
          'm-[auto] flex w-full flex-1 flex-col items-center justify-center',
          'lg:h-full lg:max-h-[580px]'
        )}
      >
        <div
          className={clsx(
            'flex h-full w-full max-w-5xl flex-col items-center justify-center self-center rounded-lg bg-[#ffffff] px-4',
            'lg:max-h-[580px] lg:shadow-cust-1'
          )}
        >
          <div className="mb-4 flex items-center justify-center bg-gradient-to-b from-[#896efd] to-[#4f28f8] bg-clip-text text-4xl font-bold text-transparent">
            {t('deploymentInProgress.message')}
          </div>
          <div className="max-w-[450px] text-center">
            <HeadingText className="mb-2 font-semibold">
              {t('deploymentInProgress.title')}
            </HeadingText>
            <HeadingText className="mb-2 font-semibold">
              {t('deploymentInProgress.subtitle')}
            </HeadingText>
          </div>
        </div>
        {/* Footer Mobile */}
        <div className="flex w-full flex-col items-center justify-center bg-white p-4 md:hidden">
          <div className="text-sm">
            {t('needHelp')} {t('contactUs')} <SupportEmail />
          </div>
          <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
        </div>
      </div>
      {/* Footer Desktop */}
      <div className="mb-6 hidden flex-col items-center justify-center pt-4 md:flex">
        <div className="text-sm">
          {t('needHelp')} {t('contactUs')} <SupportEmail />
        </div>
        <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
      </div>
    </div>
  );
};

export default DeploymentInProgress;
