import clsx from 'clsx';
import CompanyDetailsHeader from '../../shared/components/CompanyDetailsHeader/CompanyDetailsHeader';
import ConnectionStatus from '../../shared/components/ConnectionStatus/ConnectionStatus';
import TestBanner from './TestBanner';
import ConnectionIcons from '../VideoInterview/components/ConnectionIcons';
import { useTranslation } from 'react-i18next';
import EyeOpenIcon from '../../shared/components/Icons/EyeOpenIcon';
import EyeCloseIcon from '../../shared/components/Icons/EyeCloseIcon';
import { MutableRefObject, useState } from 'react';
import RecLittleIcon from '../../shared/components/Icons/RecLittleIcon';
import Button from '../../shared/components/Button/Button';
import { SupportEmail } from '../../shared/components/SupportEmail/SupportEmail';
import { useAppSelector } from '../../store/store';
import { getCompanyBrandCustomizations } from '../../store/exam/selectors';
import { convertHexToRGBA } from '../../shared/utils/colors';

interface Props {
  player: MutableRefObject<HTMLVideoElement | null>;
  connected: boolean;
  recording: boolean;
  enableStop: boolean;
  completed: boolean;
  countdown: number;
  showCountdown: boolean;
  timer: string;
  recordingHandler: () => void;
}

export default function InterviewSimulationVideo({
  player,
  connected,
  recording,
  enableStop,
  completed,
  recordingHandler,
  countdown,
  showCountdown,
  timer,
}: Props) {
  const { t } = useTranslation();
  const [overlay, setOverlay] = useState<boolean>(false);

  // Selectors
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  // Computed
  const backgroundColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 5);
  const borderColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 25);
  return (
    <>
      <div className="mx-auto flex h-full flex-col md:max-w-[1024px]">
        {/* Assessment details (hidden on mobile) */}
        <div className="hidden md:block">
          <CompanyDetailsHeader />
        </div>
        {/* Mobile test banner */}
        <TestBanner version="mobile" />

        <div className="m-[auto] flex w-full max-w-5xl flex-1 flex-col items-center justify-center">
          {/* Connection status Banner */}
          <ConnectionStatus
            type="banner"
            connected={connected}
          />

          {/* Card */}
          <div
            className={clsx(
              'relative mx-auto flex h-full w-full flex-col items-center',
              'md:mb-14 md:h-auto',
              'lg:max-h-[576px] lg:max-w-[1024px]'
            )}
          >
            {/* Desktop test banner */}
            <TestBanner version="desktop" />

            <div
              className={clsx(
                'relative z-20 mx-auto flex h-full w-full flex-col items-center gap-6 bg-white p-6',
                'lg:rounded-2xl lg:shadow-cust-1'
              )}
            >
              <div className="flex w-full items-center justify-between">
                {/* Question counter */}
                <div className="text-lg font-bold">
                  {t('videoInterview.counter.question')} 1 {t('videoInterview.counter.of')} 1
                </div>
                <ConnectionIcons />
              </div>
              <div
                className={clsx(
                  'relative mb-12 flex w-full rounded-lg border bg-[#FFF0E6] p-6 pb-24'
                )}
                style={{ backgroundColor, borderColor }}
              >
                {/* Question text */}
                <div
                  className={clsx(
                    'max-h-52 overflow-y-auto text-base font-semibold text-[#111]',
                    'lg:max-h-36 lg:text-2xl'
                  )}
                >
                  {t('interviewSimulation.question.text')}
                </div>
                {/* Video Mirror */}
                {/* Video overlay */}
                {overlay && (
                  <div
                    className={clsx(
                      'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
                      'h-32 w-32 rounded-full border-8 border-white bg-black bg-opacity-60 backdrop-blur-md',
                      { 'z-10': overlay },
                      { '-z-10': !overlay }
                    )}
                  ></div>
                )}
                <video
                  ref={player}
                  className={clsx(
                    'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
                    'h-32 w-32 rounded-full border-8 border-white object-cover'
                  )}
                  playsInline
                  muted={true}
                  controls={false}
                />
                {/* Overlay Button*/}
                <div
                  className={clsx(
                    'absolute -bottom-14 left-1/2 z-20 translate-x-8',
                    'flex h-10 w-10 cursor-pointer items-center justify-center',
                    'rounded-full border-2 border-white bg-[#f0f0f0] text-white backdrop-blur-lg'
                  )}
                  onClick={() => setOverlay((state: boolean) => !state)}
                >
                  {overlay ? <EyeOpenIcon color="#000" /> : <EyeCloseIcon color="#000" />}
                </div>
              </div>
              {/* Footer */}
              <div
                className={clsx(
                  'absolute bottom-0 flex w-full flex-col items-center justify-center',
                  'md:static md:bottom-auto'
                )}
              >
                {/* Recording messages */}
                {recording && (
                  <span className="flex items-center gap-2 font-bold text-[#CF222E]">
                    <RecLittleIcon color="#CF222E" />
                    {enableStop ? t('videoInterview.recording') : t('videoInterview.min.recording')}
                  </span>
                )}
                {!recording && (
                  <div
                    className={clsx(
                      'flex w-full flex-1 items-center justify-center border-b border-[#DDDDDD] pb-4 font-bold text-[#111]',
                      'lg:border-none lg:pb-0'
                    )}
                  >
                    {t('interviewSimulation.maxTime.message')}
                  </div>
                )}
                {/* Rec Button */}
                <Button
                  className={clsx(
                    'default my-6 flex gap-2'
                    // TODO: I think this two classes are not necessary, but I will leave them here as a reminder for now
                    // { success: !completed && recording && enableStop }
                    // { transparent: !completed && recording && !enableStop }
                  )}
                  disabled={completed || (!completed && recording && !enableStop)}
                  onClick={
                    (!completed && !recording) || (!completed && recording && enableStop)
                      ? recordingHandler
                      : () => null
                  }
                >
                  {/* Rec Button text */}
                  {!completed && !recording && t('interviewSimulation.button.start')}
                  {!completed && recording && !enableStop && t('videoInterview.button.min.record')}
                  {!completed &&
                    recording &&
                    enableStop &&
                    t('interviewSimulation.recording.button')}
                  {/* Rec timer */}
                  {recording && <div className="translate-y-[1px] font-mono">{timer}</div>}
                </Button>

                {/* Footer Mobile */}
                <div className="flex w-full flex-col items-center justify-center bg-[var(--page-background-color)] p-4 md:hidden">
                  <div className="text-sm">
                    {t('needHelp')} {t('contactUs')} <SupportEmail />
                  </div>
                  <span className="mt-1 text-xs font-semibold text-[#525252]">
                    {t('poweredBy')}
                  </span>
                </div>
              </div>
              {/* Countdown overlay */}
              <div
                className={clsx(
                  'absolute left-0 top-0 z-30 flex h-full w-full flex-col items-center justify-center bg-white bg-opacity-80 p-4 backdrop-blur-md',
                  'lg:justify-center',
                  { hidden: !showCountdown }
                )}
              >
                {/* Countdown */}
                <div
                  className={clsx(
                    'flex h-12 w-12 items-center justify-center rounded-full text-3xl font-extrabold backdrop-blur-lg',
                    {
                      'bg-[#ECECFE] text-[#111]': !brandCustomization?.['accent-color'],
                      'bg-[var(--accent-color)] text-[var(--text-color)]':
                        brandCustomization?.['accent-color'],
                    }
                  )}
                >
                  {countdown}
                </div>
                {/* Countdown Text */}
                <div className="my-2 text-center text-2xl font-bold text-[#111]">
                  {t('videoInterview.title')}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Desktop */}
        <div className="mb-6 hidden flex-col items-center justify-center pt-4 md:flex">
          <div className="text-sm">
            {t('needHelp')} {t('contactUs')} <SupportEmail />
          </div>
          <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
        </div>
      </div>
      ;
    </>
  );
}
