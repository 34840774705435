import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ExamSummaryStep } from '../../../interfaces';
import Button from '../../../shared/components/Button/Button';
import Card from '../../../shared/components/Card/Card';
import AngleRightIcon from '../../../shared/components/Icons/AngleRightIcon';
import CheckmarkIcon from '../../../shared/components/Icons/CheckmarkIcon';
import ClockIcon from '../../../shared/components/Icons/ClockIcon';
import EditPencil from '../../../shared/components/Icons/EditPencil';
import HardSkillListIcon from '../../../shared/components/Icons/HardSkillListIcon';
import MicrophoneIconBordered from '../../../shared/components/Icons/MicrophoneIconBordered';
import VideoIconBordered from '../../../shared/components/Icons/VideoIconBordered';
import { actions as examActions } from '../../../store/exam/reducer';
import { useAppDispatch } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';

interface AssessmentStepCardProps {
  index: number;
  step: ExamSummaryStep;
  summarySteps: ExamSummaryStep[];
  isAudio: boolean;
}

const AssessmentStepCard = ({ index, step, summarySteps, isAudio }: AssessmentStepCardProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Computed
  const nextStepIndex = summarySteps.findIndex((s) => !s.completed);

  // Handlers
  const submitHandler = (): void => {
    dispatch(uiActions.SUMMARY_SUBMIT());
    dispatch(examActions.SET_STEP_COMPLETED());
    dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
  };

  return (
    <Card
      key={`assessment-step-${index}`}
      className={clsx('last-of-type:mb-0', 'mb-4 md:!p-0 lg:!p-6', 'md:!p-0', 'lg:!h-auto lg:!p-6')}
    >
      <div
        className={clsx(
          'flex w-full flex-col p-4',
          'lg:space-between lg:flex-row lg:items-center lg:p-0'
        )}
      >
        {/* Title + Content */}
        <div className={clsx('flex flex-col', 'lg:flex-1')}>
          {/* Title */}
          <h4 className="font-semibold">{step.title}</h4>

          {/* Content */}
          <div className="text-txt-muted mt-2 flex items-center justify-start text-sm font-normal">
            <div className="mr-4 flex items-center justify-start">
              {step.type === 'Custom Questions' && <VideoIconBordered className="mr-1" />}
              {step.type === 'Soft Skill' &&
                (isAudio ? (
                  <MicrophoneIconBordered className="mr-1" />
                ) : (
                  <VideoIconBordered className="mr-1" />
                ))}
              {step.type === 'Hard Skill' && <HardSkillListIcon className="mr-1" />}
              {step.type === 'Custom Questionnaire' && (
                <EditPencil
                  width={16}
                  height={16}
                  className="mr-1"
                />
              )}
              {step.type === 'Custom Questions' && t('summary.customQuestions.subtitle')}
              {step.type === 'Soft Skill' &&
                (isAudio ? t('summary.softSkill.audio.subtitle') : t('summary.softSkill.subtitle'))}
              {step.type === 'Hard Skill' && t('summary.hardSkill.subtitle')}
              {step.type === 'Custom Questionnaire' && t('summary.customQuestionnaire.subtitle')}
            </div>
            {step.duration && (
              <div className="flex items-center justify-start">
                <ClockIcon
                  className="mr-1"
                  width={16}
                  height={16}
                />
                {step.duration >= 60 ? (
                  <>{`${Math.round(step.duration / 60)} min`}</>
                ) : (
                  <>{`${step.duration} sec`}</>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Button */}
        <div className={clsx('mt-4', 'lg:m-0')}>
          {!step.completed && (
            <Button
              className="default !w-28"
              disabled={nextStepIndex !== index}
              onClick={submitHandler}
            >
              {t('summary.button.next')}
              <AngleRightIcon
                color="var(--text-color)"
                className="ml-1"
              />
            </Button>
          )}
          {step.completed && (
            <div
              className={clsx(
                'mt-4 flex items-center justify-start font-bold text-[var(--accent-color)]',
                'lg:m-0'
              )}
            >
              <CheckmarkIcon
                className="mr-1"
                color="var(--accent-color)"
              />
              {t('summary.step.completed')}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default AssessmentStepCard;
