import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Collapsible from '../../../shared/components/Collapsible/Collapsible';
import JobHeader from '../../../shared/components/JobHeader/JobHeader';
import { SupportEmail } from '../../../shared/components/SupportEmail/SupportEmail';
import { getCurrentStep, getJobPostData, getSummarySteps } from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';
import DescriptionText from '../../Intro/components/DescriptionText';
import AssessmentStepCard from './AssessmentStepCard';

const Summary = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Selectors
  const currentStep = useAppSelector(getCurrentStep);
  const summarySteps = useAppSelector(getSummarySteps);
  const jobPostData = useAppSelector(getJobPostData);

  // Effects
  useEffect(() => {
    dispatch(uiActions.SUMMARY_START());
  }, [dispatch]);

  if (!currentStep || currentStep.type !== 'summary') {
    return null;
  }
  const isAudio = jobPostData?.soft_skill_type === 'audio';
  return (
    <div
      className={clsx(
        'flex h-full w-full flex-col',
        'lg:flex-row lg:items-start lg:justify-start lg:p-12'
      )}
    >
      {/* Left column */}
      <div className={clsx('lg:h-full lg:w-full lg:max-w-[40%] lg:py-0')}>
        {/* Column title */}
        <div className={clsx('border border-b-[#e4e4e4] py-4', 'lg:border-none')}>
          <JobHeader />
        </div>

        {/* Collapsible */}
        <div className={clsx('p-4', 'lg:p-0')}>
          <Collapsible title={jobPostData?.['summary_title'] ?? ''}>
            <DescriptionText description={jobPostData?.['summary_content'] ?? ''} />
          </Collapsible>
        </div>

        {/* Desktop Contact Us */}
        <div className={clsx('hidden flex-col py-4 text-sm', 'lg:mt-8 lg:flex')}>
          <div>
            {t('needHelp')} {t('contactUs')} <SupportEmail />
          </div>
          <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
        </div>
      </div>

      {/* Right column */}
      <div
        className={clsx(
          'mt-2 flex-1 px-4',
          'lg:ml-12 lg:flex lg:h-full lg:flex-col lg:overflow-y-hidden lg:px-0'
        )}
      >
        {/* Column title */}
        <h3 className={clsx('font-semibold', 'lg:mb-8 lg:px-8 lg:pt-2 lg:text-xl')}>
          {t('summary.list.title')}
        </h3>

        {/* Assessment step list */}
        <div className={clsx('mt-4', 'lg:mt-0 lg:flex-1 lg:overflow-y-auto lg:p-8 lg:pt-[3px]')}>
          {summarySteps.map((step, index) => {
            return (
              <AssessmentStepCard
                key={`assessment-step-${index}`}
                index={index}
                step={step}
                summarySteps={summarySteps}
                isAudio={isAudio}
              />
            );
          })}
        </div>

        {/* Mobile Contact Us */}
        <div className={clsx('flex-col py-4 text-sm', 'lg:hidden')}>
          <div className="flex flex-col items-center justify-center text-center">
            <span className="mb-2 text-base font-semibold text-black">{t('needHelp')}</span>
            <span>
              {t('contactUs')} <SupportEmail />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
