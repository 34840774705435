import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../App';
import AudioAnalyserDialog from '../../shared/components/Dialog/contents/AudioAnalyserDialog';
import Dialog from '../../shared/components/Dialog/Dialog';
import useAudioAnalyser from '../../shared/hooks/useAudioAnalyser';
import { getCount, getCountDown } from '../../shared/utils/getCountDown';
import { actions } from '../../store/exam/reducer';
import { getCurrentStep, getJobPostData } from '../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import InterviewSimulationAudio from './InterviewSimulationAudio';
import InterviewSimulationDone from './InterviewSimulationDone';
import InterviewSimulationVideo from './InterviewSimulationVideo';

const DURATION: number = +import.meta.env.VITE_APP_INTERVIEW_DURATION;
const MIN_INTERVIEW_DURATION: number = +import.meta.env.VITE_APP_MIN_INTERVIEW_DURATION;
const TICK_INTERVAL = Number(import.meta.env.VITE_NO_AUDIO_CHECK_TICK_INTERVAL);
const LIMIT = Number(import.meta.env.VITE_NO_AUDIO_CHECK_LIMIT);
const THRESHOLD = Number(import.meta.env.VITE_NO_AUDIO_CHECK_THRESHOLD);

const InterviewSimulation = () => {
  // Hooks
  const { connected } = useSocketInstance();
  const dispatch = useAppDispatch();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  const navigate = useNavigate();
  const { startAnalyser, stopAnalyser } = useAudioAnalyser(
    TICK_INTERVAL, // tick interval (in milliseconds)
    LIMIT, // no-audio limit (in milliseconds)
    THRESHOLD, // volume threshold (volume can be between 0.xx and more)
    () => {
      handleStop(); // Stop recording
      setIsDialogOpen(true); // Open dialog
    }
  );

  // Selectors
  const jobPostData = useAppSelector(getJobPostData);
  const currentStep = useAppSelector(getCurrentStep);

  // Refs
  const videoPlayerRef = useRef<HTMLVideoElement | null>(null);
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const stream = useRef<MediaStream | undefined>();
  const recorder = useRef<MediaRecorder | undefined>();
  const elapsed = useRef<number>(0);

  // State
  const [height] = useState<number>(window.innerHeight);
  const [width] = useState<number>(window.innerWidth);
  const [showCountdown, setShowCountdown] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(5);
  const [recording, setRecording] = useState<boolean>(false);
  const [timer, setTimer] = useState<string>(getCountDown(DURATION, elapsed?.current));
  const [audioTimer, setAudioTimer] = useState<string>(getCount(elapsed?.current));
  const [completed, setCompleted] = useState<boolean>(false);
  const [enableStop, setEnableStop] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Computed
  const isAudioOnly =
    jobPostData?.soft_skill_type === 'audio' && currentStep?.group !== 'custom-questions';

  // Handlers
  const handleStop = (): void => {
    setRecording(false);
    stream.current?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    recorder.current && recorder.current.removeEventListener('stop', handleStop);
  };

  const stopButtonClickHandler = useCallback((): void => {
    setSubmitted(true);
    recorder.current && recorder.current.stop();
    stopAnalyser();
    setCompleted(true);
  }, [stopAnalyser]);

  const recordingHandler = async (): Promise<void> => {
    if (!recording) {
      setShowCountdown(true);
    } else {
      stopButtonClickHandler();
    }
  };

  // Effects
  // Handle video and audio stream from user devices
  useEffect(() => {
    const createStream = async (): Promise<void> => {
      try {
        stream.current = await navigator.mediaDevices.getUserMedia({
          video: isAudioOnly
            ? false
            : {
                width: width > 640 ? 640 : width,
                height: width > 640 ? 360 : height,
                aspectRatio: 16 / 9,
                frameRate: { ideal: 30 },
              },
          audio: {
            sampleSize: 16,
            channelCount: 1,
          },
        });
        if (isAudioOnly) {
          if (audioPlayerRef.current) {
            audioPlayerRef.current.srcObject = stream.current;
            audioPlayerRef.current.onloadedmetadata = () => audioPlayerRef.current?.play();
          }
        } else {
          if (videoPlayerRef.current) {
            videoPlayerRef.current.srcObject = stream.current;
            videoPlayerRef.current.onloadedmetadata = () => videoPlayerRef.current?.play();
          }
        }
      } catch (e) {
        // Silently suppress eventual errors on page refresh
      }
    };
    createStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAudioOnly]);

  // Handle recording elapsed time
  useEffect(() => {
    let recordingInterval: ReturnType<typeof setInterval> | undefined;

    if (recording) {
      recordingInterval = setInterval(() => {
        elapsed.current = elapsed.current + 1;
        setTimer(getCountDown(DURATION, elapsed.current));
        setAudioTimer(getCount(elapsed.current));
      }, 1000);
    } else {
      clearInterval(recordingInterval);
    }

    return () => {
      clearInterval(recordingInterval);
    };
  }, [recording]);

  // Handle time expiration
  useEffect(() => {
    if (elapsed.current === DURATION) {
      stopButtonClickHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elapsed.current, recorder]);

  // Handle stop recording button
  useEffect(() => {
    if (elapsed.current >= MIN_INTERVIEW_DURATION && !enableStop) {
      setEnableStop(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elapsed.current]);

  // Handle countdown when recording button is pressed
  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;

    if (showCountdown) {
      interval = setInterval(() => {
        setCountdown((state: number) => state - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [showCountdown]);

  // Handle countdown expiration
  useEffect(() => {
    if (countdown === 0 && !completed) {
      setShowCountdown(false);
      startAnalyser();
      setRecording(true);

      if (stream && stream.current) {
        recorder.current = new MediaRecorder(stream.current);
        recorder.current.addEventListener('stop', handleStop);
        recorder.current.state === 'inactive' && recorder.current.start(1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown, completed, stream, recorder]);

  if (isDialogOpen) {
    return (
      <Dialog isOpen={isDialogOpen}>
        <AudioAnalyserDialog
          onClick={() => {
            // Reset test device done
            dispatch(actions.TEST_DEVICE_DONE(false));
            navigate(`/${job_post_alias}/${application_alias}/test-devices`, {
              replace: true,
              state: {
                from: 'simulate-interview-prompt',
              },
            });
          }}
        />
      </Dialog>
    );
  }

  return submitted ? (
    <InterviewSimulationDone />
  ) : isAudioOnly ? (
    <InterviewSimulationAudio
      player={audioPlayerRef}
      connected={connected}
      recording={recording}
      enableStop={enableStop}
      completed={completed}
      recordingHandler={recordingHandler}
      countdown={countdown}
      showCountdown={showCountdown}
      timer={audioTimer}
      stream={stream.current}
      duration={DURATION}
    />
  ) : (
    <InterviewSimulationVideo
      player={videoPlayerRef}
      connected={connected}
      recording={recording}
      enableStop={enableStop}
      completed={completed}
      recordingHandler={recordingHandler}
      countdown={countdown}
      showCountdown={showCountdown}
      timer={timer}
    />
  );
};

export default InterviewSimulation;
